import { useMutation } from "services/Client";
import { PATIENT_NOTE_GET_MANY } from "services/Client/schemas/queries/patient_note";
import { useNotification } from "hooks";

export default function useDeletePatientNote(id) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "patient.note.delete.one",
    update: (cache, { data: { delete_patient_note_by_pk } }) => {
      const prevData = cache.readQuery({
        query: PATIENT_NOTE_GET_MANY,
        variables: { where: { patientId: { _eq: id } } },
      });
      console.log(prevData)
      const newData = prevData.patient_note.filter(
        (patient_note) =>
          patient_note.id !== delete_patient_note_by_pk.id
      );
      const data = {
        patient_note: [
          ...newData,
        ],
      };
      console.log(data)
      cache.writeQuery({
        query: PATIENT_NOTE_GET_MANY,
        data,
        variables: { where: { patientId: { _eq: id } } },
      });
    },
    onCompleted: () => {
      notification.success({
        message: "Remarque Supprimée",
      });
    },
  });
  return mutate;
}
