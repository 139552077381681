import gql from "graphql-tag";

import { ConsultationVitalsPart } from "../fragments/consultation_vitals";
export const CONSULTATION_VITALS_GET_MANY = gql`
  query consultation_vitals(
    $limit: Int
    $offset: Int
    $order_by: [consultation_vitals_order_by!]
    $where: consultation_vitals_bool_exp
  ) {
    consultation_vitals(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      ...ConsultationVitalsPart
    }
  }
  ${ConsultationVitalsPart}
`;

export const CONSULTATION_VITALS_GET_ONE = gql`
  query consultation_vitals_by_pk($id: uuid!) {
    consultation_by_pk(id: $id) {
      ...ConsultationVitalsPart
    }
  }
  ${ConsultationVitalsPart}
`;

const schema = {
  "consultation.vitals.get.many": CONSULTATION_VITALS_GET_MANY,
  "consultation.vitals.get.one": CONSULTATION_VITALS_GET_ONE,
};

export default schema;
