import React from "react";
import get from "lodash/get";
import { Formik, Form } from "formik";
import { object, string } from "yup";
import { Field } from "formik";
import { FormikFieldInput } from "shared";

import { useQuery, useMutation } from "services/Client";
import useUploadImage from "shared/useUploadImage";
import { useNotification } from "hooks";
export default function Profile() {
  const [loading, setLoading] = React.useState(false);
  const notification = useNotification();
  const { data } = useQuery({ event: "get.user.profile" });
  const { uploadImage } = useUploadImage("profile");
  const { mutate } = useMutation({
    event: "user.profile.update",
    onCompleted: () =>
      notification.success({ message: "Modifications enregistrées" }),
  });

  const user = get(data, "get_user_profile", {
    first_name: "",
    last_name: "",
    email: "",
    picture: null,
    title: "",
    organization: { name: "" },
  });
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        picture: user.picture,
        organization: user.organization.name,
        file: null,
      }}
      onSubmit={(values, { resetForm }) => {
        const { file, ...rest } = values;
        setLoading(true);
        if (file) {
          uploadImage({
            file,
          }).then((res) =>
            mutate({
              variables: {
                ...rest,
                picture: res,
              },
            })
              .then(() => {
                setLoading(false);
              })
              .catch(() => setLoading(false))
          );
        } else {
          mutate({
            variables: {
              ...rest,
            },
          })
            .then(() => {
              setLoading(false);
            })
            .catch(() => setLoading(false));
        }
      }}
      validationSchema={object({
        first_name: string().required("error"),
        last_name: string().required("error"),
        email: string().email("error").required("error"),
      })}
    >
      {({ setFieldValue, values: { picture, file }, dirty }) => (
        <Form className="divide-y divide-gray-200 lg:col-span-9">
          {/* Profile section */}
          <div className="py-6 px-4 sm:p-6 lg:pb-8">
            <div>
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Profile
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                This information will be displayed publicly so be careful what
                you share.
              </p>
            </div>

            <div className="mt-6 flex flex-col lg:flex-row">
              <div className="flex-grow space-y-6">
                <div className="mt-6 grid grid-cols-12 gap-6">
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Prénom
                    </label>
                    <Field
                      type="text"
                      name="first_name"
                      component={FormikFieldInput}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nom
                    </label>
                    <Field
                      type="text"
                      name="last_name"
                      component={FormikFieldInput}
                    />
                  </div>

                  <div className="col-span-12">
                    <label
                      htmlFor="company"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Organisation
                    </label>
                    <Field
                      type="text"
                      name="organization"
                      component={FormikFieldInput}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-grow-0 lg:flex-shrink-0">
                <p
                  className="text-sm font-medium text-gray-700"
                  aria-hidden="true"
                >
                  Photo
                </p>
                <div className="mt-1 lg:hidden">
                  <div className="flex items-center">
                    <div
                      className="flex-shrink-0 inline-block rounded-full overflow-hidden h-12 w-12"
                      aria-hidden="true"
                    >
                      {file ? (
                        <img
                          className="rounded-full h-full w-full object-cover"
                          src={URL.createObjectURL(file)}
                          alt=""
                        />
                      ) : picture ? (
                        <img
                          className="rounded-full h-full w-full object-cover"
                          src={picture}
                          alt=""
                        />
                      ) : (
                        <img
                          className="rounded-full h-full w-full object-cover"
                          src={
                            "https://res.cloudinary.com/geerd/image/upload/v1594317458/admission_attachements/1594317457861_defaul-avatar_0.jpg"
                          }
                          alt=""
                        />
                      )}
                    </div>
                    <div className="ml-5 rounded-md shadow-sm">
                      <div className="group relative border border-gray-300 rounded-md py-2 px-3 flex items-center justify-center hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500">
                        <label
                          htmlFor="mobile-user-photo"
                          className="relative text-sm leading-4 font-medium text-gray-700 pointer-events-none"
                        >
                          <span>Change</span>
                          <span className="sr-only"> user photo</span>
                        </label>
                        <input
                          id="mobile-user-photo"
                          name="user-photo"
                          type="file"
                          onChange={(e) => setFieldValue("file", e.target.files[0])}
                          className="absolute w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hidden relative rounded-full overflow-hidden lg:block">
                  {file ? (
                    <img
                      className="relative rounded-full w-40 h-40 object-cover"
                      src={URL.createObjectURL(file)}
                      alt=""
                    />
                  ) : picture ? (
                    <img
                      className="relative rounded-full w-40 h-40 object-cover"
                      src={picture}
                      alt=""
                    />
                  ) : (
                    <img
                      className="relative rounded-full w-40 h-40 object-cover"
                      src={
                        "https://res.cloudinary.com/geerd/image/upload/v1594317458/admission_attachements/1594317457861_defaul-avatar_0.jpg"
                      }
                      alt=""
                    />
                  )}
                  <label
                    htmlFor="desktop-user-photo"
                    className="absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100"
                  >
                    <span>Change</span>
                    <span className="sr-only"> user photo</span>
                    <input
                      type="file"
                      id="desktop-user-photo"
                      name="user-photo"
                      onChange={(e) => setFieldValue("file", e.target.files[0])}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Privacy section */}
          <div className="divide-y divide-gray-200">
            <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
              <button
                type="button"
                className="bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                Annuler
              </button>
              <button
                type="submit"
                disabled={!dirty}
                className="disabled:opacity-50 ml-5 bg-primary-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                Enregistrer
                {loading && (
                  <svg
                    className="animate-spin ml-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
