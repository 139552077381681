import { useMutation } from "services/Client";
import { useNotification } from "hooks";

export default function useTerminateConsultation(id) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "consultation.update.one",
    variables: { pk_columns: { id }, _set: { status: "finished" } },
    onCompleted: () => {
      notification.success({
        message: "Consultation Terminée",
      });
    },
  });
  return mutate;
}
