import gql from "graphql-tag";

export const FILE_INSERT_ONE = gql`
  mutation insert_patient_file_one(
    $object: patient_file_insert_input!
  ) {
    insert_patient_file_one(object: $object) {
      id
      file {
        id
        label
      }
    }
  }
`;

export const FILE_UPDATE_ONE = gql`
  mutation update_patient_file_by_pk(
    $pk_columns: patient_file_pk_columns_input!
    $_set: patient_file_set_input
  ) {
    update_patient_file_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
      file {
        id
        label
      }
    }
  }
`;

export const FILE_DELETE = gql`
  mutation delete_patient_file_by_pk($id: uuid!) {
    delete_patient_file_by_pk(id: $id) {
      id
      file {
        id
        label
      }
    }
  }
`;

const schema = {
  "patient.file.insert.one": FILE_INSERT_ONE,
  "patient.file.update.one": FILE_UPDATE_ONE,
  "patient.file.delete.one": FILE_DELETE,
};

export default schema;
