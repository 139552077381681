import gql from "graphql-tag";

export const GET_PROFILE = gql`
  query get_user_profile {
    get_user_profile {
      id
      email
      first_name
      last_name
      role
      picture
      title
      organization {
        id
        name
      }
    }
  }
`;

const user_queries = {
  "get.user.profile": GET_PROFILE,
};
export default user_queries;
