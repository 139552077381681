import { useMutation } from "services/Client";
import { PATIENT_FILE_GET_MANY } from "services/Client/schemas/queries/patient_file";
import { useNotification } from "hooks";

export default function useCreatePatientFile(id) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "patient.file.insert.one",
    update: (cache, { data: { insert_patient_file_one } }) => {
      const prevData = cache.readQuery({
        query: PATIENT_FILE_GET_MANY,
        variables: {
          where: { patientId: { _eq: id } },
          order_by: { created_at: "desc" },
        },
      });
      const data = {
        patient_file: [insert_patient_file_one, ...prevData.patient_file],
      };
      cache.writeQuery({
        query: PATIENT_FILE_GET_MANY,
        data,
        variables: {
          where: { patientId: { _eq: id } },
          order_by: { created_at: "desc" },
        },
      });
    },
    onCompleted: () => {
      notification.success({
        message: "Fichier Sauvegardée",
      });
    },
  });
  return mutate;
}
