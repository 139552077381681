import gql from "graphql-tag";

import { PatientPart } from "../fragments/patient";

export const PATIENT_CREATE = gql`
  mutation insert_patient_one($object: patient_insert_input!) {
    insert_patient_one(object: $object) {
      ...PatientPart
    }
  }
  ${PatientPart}
`;

export const PATIENT_UPDATE = gql`
  mutation update_patient_by_pk(
    $pk_columns: patient_pk_columns_input!
    $_set: patient_set_input
  ) {
    update_patient_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...PatientPart
    }
  }
  ${PatientPart}
`;

export const PATIENT_DELETE = gql`
  mutation delete_patient_by_pk($id: uuid!) {
    delete_patient_by_pk(id: $id) {
      id
    }
  }
`;

const schema = {
  "patient.create": PATIENT_CREATE,
  "patient.update": PATIENT_UPDATE,
  "patient.delete": PATIENT_DELETE,
};

export default schema;
