import React, { useState } from "react";
import { useQuery } from "services/Client";
import { DndContext, DragOverlay } from "@dnd-kit/core";

import get from "lodash/get";
import { Droppable, Draggable } from "shared";
import {
  useCreateConsultationNote,
  useCreateNoteTag,
  useDeleteNoteTag,
  useDeleteConsultationNote
} from "./hooks";
export default function Note({ id }) {
  const [note, setNote] = useState("");
  const [activeId, setActiveId] = useState(null);

  const { data: notesData } = useQuery({
    event: "consultation.note.get.many",
    variables: { where: { consultationId: { _eq: id } } },
    skip: !id,
  });
  const notes = get(notesData, "consultation_note", []);

  const { data: tagsData } = useQuery({ event: "tag.get.many" });
  const tags = get(tagsData, "tag", []);

  const saveNote = useCreateConsultationNote(id, note);
  const deleteConsultationNote = useDeleteConsultationNote(id);

  const createNoteTag = useCreateNoteTag();
  const deleteNoteTag = useDeleteNoteTag();

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }
  function handleDragEnd(e) {
    createNoteTag({
      variables: { object: { noteId: e.over.id, tagId: e.active.id } },
    });
    setActiveId(null);
  }
  console.log(notes)
  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
        <div className="divide-y divide-gray-200">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="notes-title" className="text-lg font-medium text-gray-900">
              Remarques
            </h2>
          </div>
          <div className="px-4 py-6 sm:px-6">
            <ul className="space-y-8">
              {notes.map((note) => (
                <li key={note.id}>
                  <Droppable
                    note={note}
                    onDeleteNote={deleteConsultationNote}
                    id={note.note.id}
                    onDeleteTag={deleteNoteTag}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-6 sm:px-6">
          <div className="flex space-x-3">
            <div className="min-w-0 flex-1">
              <div>
                <label htmlFor="note" className="sr-only">
                  Remarque
                </label>
                <textarea
                  id="note"
                  name="note"
                  rows={3}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                  placeholder="Saisir remarque...."
                />
              </div>
              <div className="mt-3 flex items-center justify-between">
                <div className="space-x-2">
                  {tags.map((tag) => (
                    <Draggable id={tag.id}>{tag.label}</Draggable>
                  ))}
                  <DragOverlay>
                    {activeId ? (
                      <Draggable>
                        <span className="cursor-pointer inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                          {tags.filter((tag) => tag.id === activeId)[0].label}
                        </span>
                      </Draggable>
                    ) : null}
                  </DragOverlay>
                </div>
                <button
                  type="submit"
                  onClick={() => {
                    saveNote();
                    setNote("");
                  }}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DndContext>
  );
}
