import * as Yup from "yup";
import get from "lodash/get";
import { useFormik } from "formik";
import { useQuery } from "services/Client";
import { useCreatePatient } from "./hooks";
import { Input } from "shared";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const genders = [
  { id: "male", name: "Homme" },
  { id: "female", name: "Femme" },
  { id: "notsay", name: "Autre" },
];

export default function CreatePatient({ ...props }) {
  const CreatePatientSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    cin: Yup.string().required("Required"),
    birth_date: Yup.date().required("Required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "to short")
      .max(10, "to long"),
  });
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      birth_date: "",
      cin: "",
      insuranceId: null,
      gender: "notsay",
      phone_number: "",
      address: "",
    },
    validationSchema: CreatePatientSchema,
    onSubmit: () => mutate(),
  });
  const { values, handleChange, errors, handleBlur, handleSubmit, touched } =
    formik;
  const mutate = useCreatePatient(values);

  const { data: insuranceData } = useQuery({ event: "insurance.get.many" });
  const insurances = get(insuranceData, "insurance", []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="bg-white shadow px-4 py-5 rounded-t-lg sm:p-6">
          <div className="grid grid-cols-1 gap-6 ">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Créer Patient
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Veuillez saisir les informations de base
              </p>
            </div>
            <div className="mt-5 md:mt-0">
              <div className="grid grid-cols-2 gap-6">
                <div {...props}>
                  <Input
                    label="Prénom"
                    id="first_name"
                    type="text"
                    name="first_name"
                    required
                    error={errors.first_name}
                    value={values.first_name}
                    touched={touched.first_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>

                <div {...props}>
                  <Input
                    label="Nom"
                    id="last_name"
                    type="text"
                    name="last_name"
                    required
                    error={errors.last_name}
                    touched={touched.last_name}
                    value={values.last_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>

                <div {...props}>
                  <label
                    htmlFor="location"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Sexe
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    onChange={handleChange}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    defaultValue="notsay"
                  >
                    {genders.map((c) => (
                      <option value={c.id}>{c.name}</option>
                    ))}
                  </select>
                </div>

                <div {...props}>
                  <label
                    htmlFor="location"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Assurance
                  </label>
                  <select
                    id="insuranceId"
                    name="insuranceId"
                    onChange={handleChange}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    defaultValue="notsay"
                  >
                    <option value={null}>Aucune</option>
                    {insurances.map((c) => (
                      <option value={c.id}>{c.name}</option>
                    ))}
                  </select>
                </div>

                <div {...props}>
                  <Input
                    label="CIN"
                    id="cin"
                    type="text"
                    name="cin"
                    required
                    error={errors.cin}
                    touched={touched.cin}
                    value={values.cin}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>

                <div {...props}>
                  <Input
                    label="Date de naissance"
                    id="birth_date"
                    type="date"
                    name="birth_date"
                    required
                    error={errors.birth_date}
                    touched={touched.birth_date}
                    value={values.birth_date}
                    onBlur={handleBlur}
                    placeholder="YYYY-MM-DD"
                    onChange={handleChange}
                  />
                </div>
                <div {...props}>
                  <Input
                    label="Adresse"
                    id="address"
                    type="text"
                    name="address"
                    error={errors.address}
                    touched={touched.address}
                    value={values.address}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>

                <div {...props}>
                  <Input
                    label="Téléphone"
                    id="phone_number"
                    type="text"
                    name="phone_number"
                    error={errors.phone_number}
                    touched={touched.phone_number}
                    value={values.phone_number}
                    onBlur={handleBlur}
                    placeholder="06XXXXXXXX"
                    onChange={handleChange}
                  />
                </div>

                <div {...props}>
                  <Input
                    label="Ville"
                    id="city"
                    type="text"
                    name="city"
                    error={errors.city}
                    touched={touched.city}
                    value={values.city}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-3 shadow bg-gray-50 text-right sm:rounded-b-lg sm:px-6">
          <button
            type="submit"
            className="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </>
  );
}
