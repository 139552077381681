import gql from "graphql-tag";
export const PATIENT_GET_BY_DAY = gql`
  query Get_patient_day($end: Date!, $start: Date!) {
    patient_get_count_day(start: $start, end: $end) {
      time
      total
    }
  }
`;

export const PATIENT_GET_BY_MONTH = gql`
  query Get_patient_month($end: Date!, $start: Date!) {
    patient_get_count_month(start: $start, end: $end) {
      time
      total
    }
  }
`;

export const PATIENT_GET_BY_YEAR = gql`
  query Get_patient_year($end: Date!, $start: Date!) {
    patient_get_count_year(start: $start, end: $end) {
      time
      total
    }
  }
`;

export const CONSULTATION_GET_BY_DAY = gql`
  query Get_consultation_day(
    $end: Date!
    $start: Date!
    $status: String
    $hasInsurance: Boolean
  ) {
    consultation_get_count_day(
      start: $start
      end: $end
      status: $status
      hasInsurance: $hasInsurance
    ) {
      time
      total
    }
  }
`;

export const CONSULTATION_GET_BY_MONTH = gql`
  query Get_consultation_month(
    $end: Date!
    $start: Date!
    $status: String
    $hasInsurance: Boolean
  ) {
    consultation_get_count_month(
      start: $start
      end: $end
      status: $status
      hasInsurance: $hasInsurance
    ) {
      time
      total
    }
  }
`;

export const CONSULTATION_GET_BY_YEAR = gql`
  query Get_consultation_year(
    $end: Date!
    $start: Date!
    $status: String
    $hasInsurance: Boolean
  ) {
    consultation_get_count_year(
      start: $start
      end: $end
      status: $status
      hasInsurance: $hasInsurance
    ) {
      time
      total
    }
  }
`;

export const PAYMENT_GET_BY_DAY = gql`
  query Get_payment_day($end: Date!, $start: Date!, $hasInsurance: Boolean) {
    payment_get_count_day(
      start: $start
      end: $end
      hasInsurance: $hasInsurance
    ) {
      time
      total
    }
  }
`;

export const PAYMENT_GET_BY_MONTH = gql`
  query Get_payment_month($end: Date!, $start: Date!, $hasInsurance: Boolean) {
    payment_get_count_month(
      start: $start
      end: $end
      hasInsurance: $hasInsurance
    ) {
      time
      total
    }
  }
`;

export const PAYMENT_GET_BY_YEAR = gql`
  query Get_payment_year($end: Date!, $start: Date!, $hasInsurance: Boolean) {
    payment_get_count_year(
      start: $start
      end: $end
      hasInsurance: $hasInsurance
    ) {
      time
      total
    }
  }
`;

const chart_queries = {
  "patient.get.by.day": PATIENT_GET_BY_DAY,
  "patient.get.by.month": PATIENT_GET_BY_MONTH,
  "patient.get.by.year": PATIENT_GET_BY_YEAR,
  "consultation.get.by.day": CONSULTATION_GET_BY_DAY,
  "consultation.get.by.month": CONSULTATION_GET_BY_MONTH,
  "consultation.get.by.year": CONSULTATION_GET_BY_YEAR,
  "payment.get.by.day": PAYMENT_GET_BY_DAY,
  "payment.get.by.month": PAYMENT_GET_BY_MONTH,
  "payment.get.by.year": PAYMENT_GET_BY_YEAR,
};

export default chart_queries;
