import gql from "graphql-tag";
export const PRICING_PLAN_GET_MANY = gql`
  query pricing_plan(
    $limit: Int
    $offset: Int
    $order_by: [pricing_plan_order_by!]
    $where: pricing_plan_bool_exp
  ) {
    pricing_plan(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      label
      price
    }
  }
`;

const schema = {
  "pricing.plan.get.many": PRICING_PLAN_GET_MANY,
};

export default schema;
