import client from "./client";
import tag from "./tag";
import patient from "./patient";
import consultation from "./consultation";
import consultation_note from "./consultation_note";
import patient_note from "./patient_note";
import patient_file from "./patient_file";
import vitals from "./consultation_vitals";
import pricing_plan from "./pricing_plan";
import user from "./user";
import insurance from "./insurance";
import chart from "./chart"
const schemas = {
  ...client,
  ...tag,
  ...patient,
  ...consultation,
  ...consultation_note,
  ...patient_note,
  ...patient_file,
  ...vitals,
  ...pricing_plan,
  ...user,
  ...insurance,
  ...chart
};
export default schemas;
