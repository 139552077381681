import React, { useState, useEffect } from "react";
import { useQuery } from "services/Client";
import { useParams, Link } from "react-router-dom";
import { useGcal } from "@othpwn/performed-cognito-wrapper";

import get from "lodash/get";
import { useTerminateConsultation, useUpdateConsultationPlan } from "./hooks";
import Vitals from "./vitals_form";
import Pricing from "./pricing";
import Note from "./note";
import Paid from "./paid";
export default function Patient() {
  const { groups } = useGcal();

  const [amountPaid, setAmountPaid] = useState(0);
  const [edit, setEdit] = useState(false);

  const { id } = useParams();
  const updateConsultation = useUpdateConsultationPlan();

  const terminateConsultation = useTerminateConsultation(id);
  const { data: consultationData, loading } = useQuery({
    event: "consultation.get.one",
    variables: { id },
    skip: !id,
  });

  const { data: plansData } = useQuery({
    event: "pricing.plan.get.many",
  });

  const consultation = get(consultationData, "consultation_by_pk");
  const patient = get(consultation, "patient");
  const first_name = get(patient, "first_name");
  const last_name = get(patient, "last_name");
  const status = get(consultation, "status");
  const amount_paid = get(consultation, "amount_paid");

  const name = first_name + " " + last_name;
  const patientId = get(patient, "id");

  const created_at = new Date(
    get(consultation, "created_at")
  ).toLocaleDateString("fr-FR", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const selected = get(consultation, "pricing_plan.id");

  const plans = get(plansData, "pricing_plan", []);

  useEffect(() => {
    if (status === "pending") {
      setEdit(true);
    }
  }, [status]);
  useEffect(() => {
    setAmountPaid(amount_paid);
  }, [amount_paid]);
  return (
    <div className="">
      <div className="bg-white rounded-lg shadow py-7 sm:rounded-t-lgmax-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div className="flex items-center space-x-5 ">
          <div className="flex-shrink-0">
            <div className="relative">
              <span
                className="absolute inset-0 shadow-inner rounded-full"
                aria-hidden="true"
              />
            </div>
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{name}</h1>
            <p className="text-sm font-medium text-gray-500">
              Consultation créée le{" "}
              <time className="text-gray-900" dateTime="2020-08-25">
                {created_at}
              </time>
            </p>
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <Link
            to={`/patient/${patientId}`}
            className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
          >
            Profil Patient
          </Link>
          {!loading &&
            (edit ? (
              <button
                type="button"
                onClick={() =>
                  terminateConsultation().then(() => setEdit(false))
                }
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              >
                Terminer
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setEdit(true)}
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              >
                Modifier
              </button>
            ))}
        </div>
      </div>

      <div className=" mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
       

          <div className="relative space-y-6 lg:col-start-1 lg:col-span-2">
            {/* Description list*/}
            {(!edit || !groups.includes('administrator')) && (
          <div className="absolute h-full w-full bg-white bottom-0 left-0 z-10 opacity-40" />
        )}
            <section aria-labelledby="applicant-information-title">
              <Vitals id={id} />
            </section>

            {/* Comments*/}
            <section aria-labelledby="notes-title">
              <Note id={id} />
            </section>
          </div>
        

        <section
          aria-labelledby="timeline-title"
          className="relative lg:col-start-3 lg:col-span-1"
        >
           {(!edit) && (
          <div className="absolute h-full w-full bg-white bottom-0 left-0 z-10 opacity-40" />)}
          <Pricing
            items={plans}
            value={selected}
            onChange={(pricingPlanId) =>
              updateConsultation({
                variables: {
                  pk_columns: { id },
                  _set: { pricingPlanId },
                },
              })
            }
          />
          <Paid
            amount={amountPaid}
            setAmount={setAmountPaid}
            submit={(pricingPlanId) =>
              updateConsultation({
                variables: {
                  pk_columns: { id },
                  _set: { amount_paid: amountPaid },
                },
              })
            }
          />
        </section>
      </div>
    </div>
  );
}
