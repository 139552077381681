import React from "react";
import { addHocs } from "shared/Addhocs";
import { Notification } from "shared";
import { Route, Routes, Navigate } from "react-router-dom";
import { GcalAuthentication } from "@othpwn/performed-cognito-wrapper";
import SplashScreen from "shared/pages/SplashScreen";
// import Alert from "services/Alert/body";

import Router from "./services/Router";
import Apollo from "./services/Apollo";
// import Layout from "layout";
import indexRoutes from "routes";
const Layout = React.lazy(
  () => import(/* webpackChunkName: "client" */ 'layout'),
)
const App = () => {
  return (
    <GcalAuthentication>
      <React.Suspense fallback={<SplashScreen />}>
        <Layout>
          <Notification />
          <Routes>
            {indexRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={<route.component />}
              />
            ))}
            <Route index element={<Navigate to="home" />} />
          </Routes>
        </Layout>
      </React.Suspense>
    </GcalAuthentication>
  );
};
export default addHocs(App, [Router, Apollo]);
