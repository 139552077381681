import React from "react";
import { useEffect } from "react";
import { useQuery } from "services/Client";
import get from "lodash/get";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { Paginator } from "shared";
import { PAGE_SIZE } from "config";
import ListItem from "./components/listItem";
export default function ListPatients() {
  let navigate = useNavigate();
  const { page = 0 } = useParams();
  const pageSize = parseInt(PAGE_SIZE);
  const { data, fetchMore } = useQuery({
    event: "patient.get.many",
    variables: {
      limit: pageSize,
      offset: page * pageSize,
      order_by: { created_at: "desc" },
    },
  });
  useEffect(() => {
    fetchMore({ variables: { offset: pageSize * page } });
  }, [page, fetchMore, pageSize]);
  const patients = get(data, "patient", []);
  const { data: patientCount } = useQuery({ event: "patient.get.count" });
  const count = get(patientCount, "patient_aggregate.aggregate.count", 0);
  console.log(count)
  return (
    <div>
      <ul className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
        {patients &&
          patients.map((patient) => (
            <li key={patient.id}>
              <Link to={`/patient/${patient.id}`}>
                <ListItem candidate={patient} />
              </Link>
            </li>
          ))}
      </ul>

      {/* Pagination */}
      <Paginator
        count={Math.ceil(count / pageSize)}
        current={parseInt(page)}
        handler={(page) => navigate(`/patients/${page}`)}
      />
    </div>
  );
}
