import gql from "graphql-tag";

export const FILE_PRESIGN = gql`
  mutation file_presign($Key: String!, $ContentType: String!) {
    file_presign(Key: $Key, ContentType: $ContentType) {
      url
      fields
    }
  }
`;


export const FILE_DOWNLOAD = gql`
  mutation file_download($fileId: uuid!) {
    file_download(fileId: $fileId) {
      url
    }
  }
`;

const schema = {
  "file.upload": FILE_PRESIGN,
  "file.download": FILE_DOWNLOAD,
};

export default schema;
