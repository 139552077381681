import { gql } from "@apollo/client";

export const ConsultationPart = gql`
  fragment ConsultationPart on consultation {
    id
    created_at
    updated_at
    status
    amount_paid
    patient {
      id
      first_name
      last_name
      address
    }
    pricing_plan {
      id
      label
      price
    }
    vitals {
      id
    }
  }
`;
