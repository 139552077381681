import { InMemoryCache, makeVar } from "@apollo/client";

export const isLoggedInVar = makeVar(false);
export const tokenVar = makeVar(undefined);
export const userIdVar = makeVar("");
export const isAuthRefreshingVar = makeVar(false);

export const alertVar = makeVar(null);
export const notificationVar = makeVar(null);
export const uploadingVar = makeVar(false);
export const paginatedViewsVar = makeVar([]);
export const searchLikeVar = makeVar("");
export const testViewTitleVar = makeVar("");

export const in_memory_cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        token: {
          read() {
            return tokenVar();
          },
        },
        userId: {
          read() {
            return userIdVar();
          },
        },
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
        paginatedViews: {
          read() {
            return paginatedViewsVar();
          },
        },
        notification: {
          read() {
            return notificationVar();
          },
        },
        alert: {
          read() {
            return alertVar();
          },
        },
        searchLike: {
          read() {
            return searchLikeVar();
          },
        },
        testViewTitle: {
          read() {
            return testViewTitleVar();
          },
        },
        patient: {
          merge(existing = [], incoming, { args: { offset } }) {
            let patients = existing.slice(0);
            incoming.forEach(
              (patient, idx) => (patients[offset + idx] = patient)
            );
            return patients;
          },
          read(existing = [], { args }) {
            // If we read the field before any data has been written to the
            // cache, this function will return undefined, which correctly
            // indicates that the field is missing.
            const page =
              existing && existing.slice(args.offset, args.offset + args.limit);
            // If we ask for a page outside the bounds of the existing array,
            // page.length will be 0, and we should return undefined instead of
            // the empty array.
            if (page && page.length > 0) {
              return page;
            }
          },
          keyArgs: false,
        },
      },
    },
  },
});

const localVariables = {
  notificationVar,
  testViewTitleVar,
  alertVar,
  uploadingVar,
  paginatedViewsVar,
  isLoggedInVar,
  tokenVar,
  searchLikeVar,
  isAuthRefreshingVar,
};

const cache = {
  cache: in_memory_cache,
  ...localVariables,
};

export default cache;
