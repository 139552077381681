import { useMutation } from "services/Client";
import { CONSULTATION_NOTE_GET_MANY } from "services/Client/schemas/queries/consultation_note";
import { useNotification } from "hooks";

export default function useDeleteConsultationNote(id) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "consultation.note.delete.one",
    update: (cache, { data: { delete_consultation_note_by_pk } }) => {
      const prevData = cache.readQuery({
        query: CONSULTATION_NOTE_GET_MANY,
        variables: { where: { consultationId: { _eq: id } } },
      });
      console.log(prevData)
      const newData = prevData.consultation_note.filter(
        (consultation_note) =>
          consultation_note.id !== delete_consultation_note_by_pk.id
      );
      const data = {
        consultation_note: [
          ...newData,
        ],
      };
      cache.writeQuery({
        query: CONSULTATION_NOTE_GET_MANY,
        data,
        variables: { where: { consultationId: { _eq: id } } },
      });
    },
    onCompleted: () => {
      notification.success({
        message: "Remarque Supprimée",
      });
    },
  });
  return mutate;
}
