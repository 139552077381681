import useQuery from "./useQuery";
import useQueryPaginated from "./useQueryPaginated";

import useMutation from "./useMutation";
import usePagination from "./usePagination";
import useClearCache from "./useClearCache";

import cache from "./cache";

import withClient from "./client";
export { useQuery };
export { useMutation };
export { withClient };
export { useQueryPaginated };
export { usePagination };
export { useClearCache };
export { cache };

const client = {
  useQuery,
  useMutation,
  useQueryPaginated,
  usePagination,
  useClearCache,
  withClient,
  cache
}
export default client