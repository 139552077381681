import gql from "graphql-tag";

export const NOTE_UPDATE_ONE = gql`
  mutation update_note_by_pk(
    $pk_columns: note_pk_columns_input!
    $_set: note_set_input
  ) {
    update_note_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
      body
    }
  }
`;

const schema = {
  "note.update.one": NOTE_UPDATE_ONE,
};

export default schema;
