import React from "react";
import {
  ChevronRightIcon,
  IdentificationIcon,
  PhoneIcon,
} from "@heroicons/react/solid";
export default function ListItem({ candidate }) {
  return (
    <div className="group block cursor-pointer">
      <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <p className="text-sm font-medium text-blue-600 truncate">
                {candidate.first_name + " " + candidate.last_name}
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                <PhoneIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="truncate">
                  {"+" +
                    candidate.phone_number_prefix +
                    " " +
                    candidate.phone_number}
                </span>
              </p>
            </div>
            <div className="hidden md:block">
              <div>
                <p className="text-sm text-gray-900">
                  Né(e) le{" "}
                  <time dateTime={candidate.birth_date}>
                    {new Date(candidate.birth_date).toLocaleDateString("fr-FR", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  })}
                  </time>
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <IdentificationIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                  {candidate.cin}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <ChevronRightIcon
            className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
  );
}
