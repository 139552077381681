import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import uuid from "react-uuid";
import axios from "axios";

const FILE_PRESIGN = gql`
  mutation file_presign($Key: String!, $ContentType: String!) {
    file_presign(Key: $Key, ContentType: $ContentType) {
      url
      fields
    }
  }
`;

export default function useUploadFile() {
  const [progress, setProgress] = useState(0);
  const [mutate] = useMutation(FILE_PRESIGN);

  async function uploadFile({ fileName, fileType, fileSize, file }) {
    // get signed post request
    // post to aws
    // -- prepare post data
    const results = await mutate({
      variables: { Key: `${uuid()}.${fileName.split('.').pop()}`, ContentType: fileType },
    });
    const { fields, url } = results.data.file_presign;
    console.log("Uploading to : ", url);
    // -- create form data
    let formData = new FormData();
    Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
    formData.append("Content-Type", fileType);
    formData.append("file", file);
    // -- post
    await axios.post(url, formData, {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
        if (percentCompleted === 100) {
          setProgress(0);
        }
        // console.log("progress", percentCompleted);
      },
    });

    return fields.key;

    // -- resource url
  }
  return { uploadFile, progress };
}
