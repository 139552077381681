import gql from "graphql-tag";
import { ConsultationPart } from "../fragments/consultation";
export const CONSULTATION_GET_MANY = gql`
  query consultation(
    $limit: Int
    $offset: Int
    $order_by: [consultation_order_by!]
    $where: consultation_bool_exp
  ) {
    consultation(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      ...ConsultationPart
    }
  }
  ${ConsultationPart}
`;

export const CONSULTATION_GET_ONE = gql`
  query consultation_by_pk($id: uuid!) {
    consultation_by_pk(id: $id) {
      ...ConsultationPart
    }
  }
  ${ConsultationPart}
`;

export const CONSULTATION_COUNT = gql`
  query consultation_aggregate {
    consultation_aggregate {
      aggregate {
        count
      }
    }
  }
`;
const schema = {
  "consultation.get.many": CONSULTATION_GET_MANY,
  "consultation.get.one": CONSULTATION_GET_ONE,
  "consultation.get.count": CONSULTATION_COUNT,
};

export default schema;
