import { Fragment, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";

import get from "lodash/get";
import { cache as ClientCache } from "services/Client";
const { notificationVar } = ClientCache;

const SuccessNotification = ({ message, handleClose }) => (
  <>
    <div className="flex-shrink-0">
      <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
    </div>
    <div className="ml-3 w-0 flex-1 pt-0.5">
      <p className="text-sm font-medium text-gray-900">Erreur</p>
      <p className="mt-1 text-sm text-gray-500">{message}</p>
    </div>
    <div className="ml-4 flex-shrink-0 flex">
      <button
        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={handleClose}
      >
        <span className="sr-only">Close</span>
        <XIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  </>
);

const NotificationWithAction = ({
  header,
  message,
  buttonLabel,
  buttonAction,
  handleClose,
}) => (
  <>
    <div className="flex-shrink-0 pt-0.5">
      <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
    </div>
    <div className="ml-3 w-0 flex-1">
      <p className="text-sm font-medium text-gray-900">{header}</p>
      <p className="mt-1 text-sm text-gray-500">{message}</p>
      {buttonLabel && (
        <div className="mt-4 flex">
          <button
            type="button"
            onClick={() => {
              buttonAction();
              handleClose();
            }}
            className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {buttonLabel}
          </button>
        </div>
      )}
    </div>
    <div className="ml-4 flex-shrink-0 flex">
      <button
        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={handleClose}
      >
        <span className="sr-only">Close</span>
        <XIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  </>
);

export default function Notification() {
  //  const { data } = useQuery({ event: "notification" });
  const data = useReactiveVar(notificationVar);
  const header = get(data, "header", "");
  const buttonLabel = get(data, "buttonLabel", "");
  const buttonAction = get(data, "buttonAction", () => null);
  const type = get(data, "type", "");

  const message = get(data, "message", "");
  const opened = get(data, "opened", false);

  useEffect(() => {
    if (opened) {
      const timer = setTimeout(() => {
        handleClose();
      }, 5000);
      return () => clearTimeout(timer);
    }
  });
  const handleClose = () => {
    notificationVar({
      message: "",
      type: "",
      opened: false,
      __typename: "notification",
    });
    return;
  };
  return (
    <>
      <div
        aria-live="assertive"
        className="fixed inset-0 z-50 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={opened}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  {type ? (
                    <SuccessNotification
                      message={message}
                      handleClose={handleClose}
                    />
                  ) : (
                    <NotificationWithAction
                      header={header}
                      buttonAction={buttonAction}
                      buttonLabel={buttonLabel}
                      handleClose={handleClose}
                      message={message}
                    />
                  )}
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
