import React, { useState } from "react";
import get from "lodash/get";
import { useFormik } from "formik";
import { Input } from "shared";
import { useQuery } from "services/Client";
import {
  useCreateConsultationVitals,
  useUpdateConsultationVitals,
} from "./hooks";
export default function Vitals({ id }) {
  const [editMode, seteditMode] = useState(false);
  const { data, loading, refetch } = useQuery({
    event: "consultation.vitals.get.many",
    variables: { where: { consultationId: { _eq: id } } },
  });
  const vitals = get(data, "consultation_vitals[0]", {
    blood_pressure_min: null,
    blood_pressure_max: null,
    weight: null,
    height: null,
    temperature: null,
    heart_rate: null,
    blood_sugar: null,
  });
  const vitals_id = get(data, "consultation_vitals[0].id");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...vitals,
    },
    onSubmit: () => (editMode ? update() : submit().then(() => refetch())),
  });
  const { values, handleChange, errors, handleBlur, handleSubmit, touched } =
    formik;
  const submit = useCreateConsultationVitals({
    ...values,
    consultationId: id,
  });
  const { __typename, ...updateValues } = values;
  const update = useUpdateConsultationVitals({
    variables: { pk_columns: { id: vitals_id }, _set: { ...updateValues } },
    onCompleted: () => seteditMode(false),
  });
  return (
    <div className="shadow sm:rounded-md sm:overflow-hidden ">
      {(!loading && !vitals_id) || editMode ? (
        <form onSubmit={handleSubmit}>
          <div className="bg-white divide-y divide-gray-200">
            <div className="py-6 px-4 sm:p-6 ">
              <h2
                id="payment-details-heading"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Rapport
              </h2>
            </div>

            <div className=" py-6 px-4 sm:p-6  grid grid-cols-3 gap-6">
              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="Poids (kg)"
                  id="weight"
                  type="number"
                  name="weight"
                  error={errors.weight}
                  value={values.weight}
                  touched={touched.weight}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>

              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="Taille (cm)"
                  id="height"
                  type="number"
                  name="height"
                  error={errors.height}
                  value={values.height}
                  touched={touched.height}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>

              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="Fréquence Cardiaque (bpm)"
                  id="heart_rate"
                  type="number"
                  name="heart_rate"
                  error={errors.heart_rate}
                  value={values.heart_rate}
                  touched={touched.heart_rate}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>

              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="Tension artérielle min (mmHg)"
                  id="blood_pressure_min"
                  type="number"
                  name="blood_pressure_min"
                  error={errors.blood_pressure_min}
                  value={values.blood_pressure_min}
                  touched={touched.blood_pressure_min}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>

              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="Tension artérielle max (mmHg)"
                  id="blood_pressure_max"
                  type="number"
                  name="blood_pressure_max"
                  error={errors.blood_pressure_max}
                  value={values.blood_pressure_max}
                  touched={touched.blood_pressure_max}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="Glycémie (g/l)"
                  id="blood_sugar"
                  type="number"
                  name="blood_sugar"
                  error={errors.blood_sugar}
                  value={values.blood_sugar}
                  touched={touched.blood_sugar}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-4 sm:col-span-1">
                <Input
                  label="Température (C)"
                  id="temperature"
                  type="number"
                  name="temperature"
                  error={errors.temperature}
                  value={values.temperature}
                  touched={touched.temperature}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              className="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
            >
              Enregistrer
            </button>
          </div>
        </form>
      ) : (
        <div className="bg-white divide-y divide-gray-200">
          <div className="py-6 px-4 sm:p-6 ">
            <h2
              id="payment-details-heading"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Rapport
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Poids (kg)
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{vitals.weight}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Taille (cm)
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{vitals.height}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Fréquence Cardiaque (bpm){" "}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {vitals.heart_rate}{" "}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Tension artérielle min (mmHg)
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {vitals.blood_pressure_min}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Tension artérielle max (mmHg)
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {vitals.blood_pressure_max}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Glycémie</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {vitals.blood_sugar}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Température
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {vitals.temperature}
                </dd>
              </div>
            </dl>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="button"
              onClick={() => seteditMode(true)}
              className="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
            >
              Modifier
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
