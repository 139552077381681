import React from 'react'

function SplashScreen() {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-100 flex flex-col items-center justify-center">
      {/* <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div> */}

      <img
        className="relative w-40 mb-12"
        src="https://res.cloudinary.com/geerd/image/upload/v1650726364/appdoc/appdoc-logos_transparent_q6hecf.png"
        alt="appdoc-logo"
      />
      <span className="text-gray-500 absolute mt-12 text-center line-clamp-3">
        Pour une meilleur gestion de votre pratique medicale
      </span>
      <div>
        <span className="absolute bottom-16 left-1/2 -ml-4 text-gray-400">
          from
        </span>
        <img
          className="w-24 absolute bottom-4 left-1/2 -ml-12"
          alt="geerd-logo"
          src="https://res.cloudinary.com/geerd/image/upload/v1636309911/geerd/base_logo_transparent_background_z0u097.png"
        />
      </div>
    </div>
  )
}

export default SplashScreen
