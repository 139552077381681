import { gql } from "@apollo/client";

export const ConsultationVitalsPart = gql`
  fragment ConsultationVitalsPart on consultation_vitals {
    id
    created_at
    updated_at
    heart_rate
    blood_pressure_min
    blood_pressure_max
    weight
    height
    temperature
    blood_sugar
  }
`;
