import gql from "graphql-tag";
import get from "lodash/get";
import { useMutation } from "services/Client";
import { useNotification } from "hooks";
import { useNavigate } from "react-router-dom";

import { PatientPart } from "services/Client/schemas/fragments/patient";

export default function useCreatePatient(values) {
  let navigate = useNavigate();
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "patient.create",
    variables: {
      object: {
        ...values,
      },
    },
    update: (cache, { data: { insert_patient_one } }) => {
      cache.modify({
        fields: {
          patient(patient = []) {
            const newPatientRef = cache.writeFragment({
              data: insert_patient_one,
              fragmentName: "NewPatient",
              fragment: gql`
                fragment NewPatient on patient {
                  ...PatientPart
                }
                ${PatientPart}
              `,
            });
            return [newPatientRef, ...patient];
          },
          patient_aggregate(oldData) {
            return {
              aggregate: {
                count: oldData.aggregate.count + 1,
              },
            };
          },
        },
      });
    },
    onCompleted: (results) => {
      const patientName =
        get(results, "insert_patient_one.first_name") +
        " " +
        get(results, "insert_patient_one.last_name");
      const patientId = get(results, "insert_patient_one.id");

      notification.success({
        header: patientName,
        message: "Profil Patient Créé",
        buttonLabel: "Créer Consultation",
        buttonAction: () => navigate(`/patient/${patientId}`),
      });
    },
  });
  return mutate;
}
