import consultation from "./consultation";
import patient from "./patient";
import consultation_note from "./consultation_note";
import patient_note from "./patient_note";
import patient_file from "./patient_file"
import vitals from "./consultation_vitals";
import note from "./note";
import note_tag from "./note_tag";
import user from "./user"
import file from "./file"
const schema = {
  ...consultation,
  ...patient,
  ...consultation_note,
  ...patient_note,
  ...patient_file,
  ...vitals,
  ...note,
  ...note_tag,
  ...user,
  ...file
};
export default schema;
