import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {Bar} from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'


export default function YearChart({data, label}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    ChartDataLabels,

    BarElement,
    Title,
    Tooltip,
    Legend,
  )
  const options = {
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          precision: 0,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {display: false},
    },
  }
  const dataChart = {
    datasets: [
      {
        borderWidth: 1,
        fill: true,
        data,
        label,
        backgroundColor: '#60a5fa',
        borderColor: '#0c4a6e',
      },
    ],
  }
  return <Bar data={dataChart} options={options} />
}
