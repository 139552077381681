import { useState } from "react";
import { useGcal } from "@othpwn/performed-cognito-wrapper";
import { useQuery } from "services/Client";
import moment from "moment";

export default function useConsultationChart() {
  const { groups } = useGcal();

  const [startdate, setStartdate] = useState(
    moment().subtract(28, "d").format("YYYY-MM-DD")
  );
  const [enddate, setEnddate] = useState(moment().format("YYYY-MM-DD"));
  const [step, setStep] = useState("d");
  const [status, setStatus] = useState("finished");
  const [hasInsurance, setHasInsurance] = useState(false);
  const { data } = useQuery({
    event: "consultation.get.by.day",
    variables: {
      start: startdate,
      end: enddate,
      status,
      hasInsurance,
    },
    skip: !groups.includes("administrator"),
  });
  const { data: consultationDataMonth } = useQuery({
    event: "consultation.get.by.month",
    variables: {
      start: moment(startdate).set("date", 1).format("YYYY-MM-DD"),
      end: enddate,
      status,
      hasInsurance,
    },
    skip: !groups.includes("administrator"),
  });
  const { data: consultationDataYear } = useQuery({
    event: "consultation.get.by.year",
    variables: {
      start: moment(startdate)
        .set("date", 1)
        .set("month", 0)
        .format("YYYY-MM-DD"),
      end: enddate,
      status,
      hasInsurance,
    },
    skip: !groups.includes("administrator"),
  });
  return {
    data:
      step === "d"
        ? data
        : step === "m"
        ? consultationDataMonth
        : step === "y"
        ? consultationDataYear
        : undefined,
    payload: { startdate, enddate, step, status, hasInsurance },
    handlers: { setStartdate, setEnddate, setStep, setStatus, setHasInsurance },
  };
}
