import React from "react";
import { useDraggable } from "@dnd-kit/core";

export default function Draggable({ children, id }) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id,
  });
  return (
    <span
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className="cursor-pointer inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-primary-100 text-primary-800"
    >
      {children}
    </span>
  );
}
