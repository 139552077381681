import { useMutation } from "services/Client";
import { useNotification } from "hooks";

export default function useUpdateConsultationPlan() {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "consultation.update.one",
    onCompleted: () => {
      notification.success({
        message: "Paiement Sauvegardé",
      });
    },
  });
  return mutate;
}
