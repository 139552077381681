import { Route, Routes, Navigate } from "react-router-dom";

import CreatePatient from "shared/CreatePatient";
import ListPatients from "./list";

export default function Patients() {
  return (
    <Routes>
      <Route
        path=":page"
        element={
          <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
            <div className="grid grid-cols-1">
              <CreatePatient className="col-span-2" />
            </div>
            <div className="sm:px-6 lg:px-8 rounded-lg bg-white pt-8 pb-16 grid grid-cols-1 gap-4 lg:col-span-2">
              {/* Stacked list */}
              <ListPatients />
            </div>
          </div>
        }
      />
      <Route
        index
        element={
          <>
            <Navigate to="0" />
          </>
        }
      />
    </Routes>
  );
}
