import React from "react";
import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/react/solid";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Input({ label, id, error, touched, ...rest }) {
  return (
    <>
      <div className="flex justify-between">
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        {rest.required && (
          <span className="text-sm text-gray-500" id="email-optional">
            Requis
          </span>
        )}
      </div>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id={id}
          {...rest}
          className={classNames(
            touched
              ? error
                ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
                : "border-green-300 text-green-900 placeholder-green-300 focus:ring-green-500 focus:border-green-500"
              : "focus:ring-blue-500 focus:border-blue-500 shadow-sm border-gray-300",
            "block w-full pr-10 focus:outline-none sm:text-sm rounded-md"
          )}
        />

        {touched && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            {error ? (
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            ) : (
              <CheckCircleIcon
                className="h-5 w-5 text-green-500"
                aria-hidden="true"
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}
