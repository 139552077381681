import React, { useState } from "react";
import { useDroppable } from "@dnd-kit/core";

import { useUpdateNote } from "./hooks";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Droppable({ note, id, onDeleteTag, onDeleteNote }) {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });
  const [editMode, seteditMode] = useState(false);
  const [body, setBody] = useState("");

  const updateNote = useUpdateNote({
    onCompleted: () => seteditMode(false),
  });
  const onButtonClick = (id = 0) => {
    editMode
      ? updateNote({
          variables: {
            pk_columns: { id },
            _set: { body },
          },
        })
      : seteditMode(true);
  };

  return (
    <div
      ref={setNodeRef}
      className={classNames(
        isOver && "rounded-md border-2 border-primary-400 p-2",
        "flex justify-between space-x-3"
      )}
    >
      <div className="w-full">
        <div className="mt-1 text-sm text-gray-700">
          {editMode ? (
            <textarea
              id="note"
              name="note"
              rows={3}
              value={body ? body : note.note.body}
              onChange={(e) => setBody(e.target.value)}
              className="shadow-sm block w-full  focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
              placeholder="Saisir remarque...."
            />
          ) : (
            <p>{note.note.body}</p>
          )}
        </div>
        <div className="mt-2 text-sm space-x-2">
          {/* <span className="text-gray-500 font-medium">{getReadableTime()}</span> */}
          <button
            type="button"
            onClick={() => onButtonClick(note.note.id)}
            className="text-gray-900 font-medium"
          >
            {editMode ? "Enregistrer" : "Modifier"}
          </button>
          <span className="text-gray-500 font-medium">&middot;</span>
          <button
            type="button"
            onClick={() => onDeleteNote({ variables: { id: note.id } })}
            className="text-gray-900 font-medium"
          >
            Supprimer
          </button>
        </div>
      </div>
      <div className="space-x-2">
        {note.note.note_tags.map((t) => (
          <span className="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-primary-100 text-primary-700">
            {t.tag.label}
            <button
              type="button"
              onClick={() =>
                onDeleteTag({
                  variables: {
                    where: {
                      tagId: { _eq: t.tag.id },
                      noteId: { _eq: note.note.id },
                    },
                  },
                })
              }
              className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-primary-400 hover:bg-primary-200 hover:text-primary-500 focus:outline-none focus:bg-primary-500 focus:text-white"
            >
              <span className="sr-only">Remove small option</span>
              <svg
                className="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </button>
          </span>
        ))}
      </div>
    </div>
  );
}
