import React from "react";
import { Route, Routes, Navigate, NavLink } from "react-router-dom";
import {
  CogIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import settings_navigation from "./routes";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const subNavigation = [
  { name: "Profile", to: "profile", icon: UserCircleIcon, current: true },
  { name: "Accounts", to: "accounts", icon: CogIcon, current: false },
];
export default function Settings() {
  return (
    <div className="max-w-screen-xl mx-auto pb-6  lg:pb-16 ">
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
          <aside className="py-6 lg:col-span-3">
            <nav className="space-y-1">
              {subNavigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.to}
                  className={({ isActive }) =>
                    classNames(
                      isActive
                        ? "bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700"
                        : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                      "group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                    )
                  }
                  aria-current={item.current ? "page" : undefined}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? "text-teal-500 group-hover:text-teal-500"
                        : "text-gray-400 group-hover:text-gray-500",
                      "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  <span className="truncate">{item.name}</span>
                </NavLink>
              ))}
            </nav>
          </aside>

          <Routes>
            {settings_navigation.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={<route.component />}
              />
            ))}
            <Route index element={<Navigate to="profile" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
