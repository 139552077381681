import React from "react";
import { createRoot } from "react-dom/client";
import { GcalProvider, GcalClient } from "@othpwn/performed-cognito-wrapper";
import { GCAL } from "env";
import "./index.css";
import App from "MyApp";

import reportWebVitals from "./reportWebVitals";
const gcalClient = new GcalClient(GCAL);

createRoot(document.getElementById("root")).render(
  <GcalProvider client={gcalClient}>
    <App />
  </GcalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
