import { useState } from "react";
import axios from "axios";
import { gql } from "@apollo/client";

import { useMutation } from "@apollo/client";

import { CLOUDFLARE_IMAGE_PREFIX } from "env";

const GET_IMAGE_UPLOAD_LINK = gql`
  mutation GET_IMAGE_UPLOAD_LINK {
    image_upload {
      uploadURL
      id
    }
  }
`;
export default function useUploadImage(variant) {
  const [progress, setProgress] = useState(0);
  const [getImageUploadLink] = useMutation(GET_IMAGE_UPLOAD_LINK);

  async function uploadImage({ file }) {
    const result = await getImageUploadLink();

    const { data } = result;
    const url = data.image_upload.uploadURL;
    const image_id = data.image_upload.id;
    // -- create form data
    let formData = new FormData();
    formData.append("file", file);
    // -- post
    await axios.post(url, formData, {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
        if (percentCompleted === 100) {
          setProgress(0);
        }
        // console.log("progress", percentCompleted);
      },
    });

    // -- resource url
    return `${CLOUDFLARE_IMAGE_PREFIX}/${image_id}/${variant}`;
  }
  return { uploadImage, progress };
}
