import { useMutation } from "services/Client";
import { useNotification } from "hooks";

export default function useCreateConsultationVitals(object) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "consultation.vitals.insert.one",
    variables: {
      object,
    },
    onCompleted: () => {
      notification.success({
        message: "Remarque Sauvegardée",
      });
    },
  });
  return mutate;
}
