import React from "react";
import { Link } from "react-router-dom";
import get from "lodash/get";
import { useQuery } from "services/Client";

export default function ListPatient() {
  const { data } = useQuery({
    event: "patient.get.many",
    variables: { limit: 5, offset: 0, order_by: { created_at: "desc" } },
  });
  const patients = get(data, "patient", []);
  return (
    <div className="rounded-lg bg-white overflow-hidden shadow">
      <div className="p-6">
        <h2
          className="text-base font-medium text-gray-900"
          id="recent-hires-title"
        >
          Patients récents
        </h2>
        <div className="flow-root mt-6">
          <ul className="-my-5 divide-y divide-gray-200">
            {patients.map((patient) => (
              <li key={patient.id} className="py-4">
                <div className="flex items-center space-x-4">
                  {/* <div className="flex-shrink-0">
                    <img
                      className="h-8 w-8 rounded-full"
                      src={person.imageUrl}
                      alt=""
                    />
                  </div> */}
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {patient.first_name} {patient.last_name}
                    </p>
                    <p className="text-sm text-gray-500 truncate">
                      {"@" + patient.cin}
                    </p>
                  </div>
                  <div>
                    <Link
                      to={`/patient/${patient.id}`}
                      className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Voir
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-6">
          <Link
            to={"/patients"}
            className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Voir plus
          </Link>
        </div>
      </div>
    </div>
  );
}
