import { gql } from "@apollo/client";

export const PatientPart = gql`
  fragment PatientPart on patient {
    id
    created_at
    first_name
    last_name
    birth_date
    gender
    cin
    phone_number
    phone_number_prefix
    address
    insurance{
      id
      name
    }
  }
`;
