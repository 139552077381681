import React from "react";
import { useQuery } from "services/Client";
import get from "lodash/get";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

import ListItem from "./components/listItem";
export default function ListPatients() {
  const [searchParams] = useSearchParams();
  const search_query = searchParams.get("query");
  const ilike = `%${search_query}%`;
  const { data } = useQuery({
    event: "patient.get.many",
    skip: !search_query,
    variables: {
      where: {
        _or: [
          { first_name: { _ilike: ilike } },
          { last_name: { _ilike: ilike } },
          { cin: { _ilike: ilike } },
        ],
      },
      order_by: { created_at: "desc" },
    },
  });

  const patients = get(data, "patient", []);
  // const { data: patientCount } = useQuery({ event: "patient.get.count" });
  // const count = get(patientCount, "patient_aggregate.aggregate.count", 0);
  return (
    <div className="sm:px-6 lg:px-8 rounded-lg bg-white p-8">
      <ul className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
        {patients &&
          patients.map((patient) => (
            <li key={patient.id}>
              <Link to={`/patient/${patient.id}`}>
                <ListItem candidate={patient} />
              </Link>
            </li>
          ))}
      </ul>

      {/* Pagination */}
      {/* <Paginator
        count={Math.ceil(count / pageSize)}
        current={parseInt(page)}
        handler={(page) => navigate(`/patients/${page}`)}
      /> */}
    </div>
  );
}
