import { gql } from "@apollo/client";

export const USER_FIELDS = gql`
  fragment USER_FIELDS on user {
    id
    created_at
    email
    first_name
    last_name
    role
  }
`;
