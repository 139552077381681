// import asyncComponent from "shared/helpers/AsyncComponent";
// const Home = asyncComponent(() => import("screens/Main"));
// const Course = asyncComponent(() => import("screens/Courses"));
// const Auth = asyncComponent(() => import("screens/Auth"));
import Profile from "./profile";
// import Accounts from "./accounts";
var settings_navigation = [
  {
    path: "profile",
    key: "Profile",
    component: Profile,
  },
  // {
  //   path: "accounts",
  //   key: "Accounts",
  //   component: Accounts,
  // },
];

export default settings_navigation;
