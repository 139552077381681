import gql from "graphql-tag";
import { ConsultationVitalsPart } from "../fragments/consultation_vitals";

export const VITALS_INSERT_ONE = gql`
  mutation insert_consultation_vitals_one(
    $object: consultation_vitals_insert_input!
  ) {
    insert_consultation_vitals_one(object: $object) {
      ...ConsultationVitalsPart
    }
  }
  ${ConsultationVitalsPart}
`;

export const VITALS_UPDATE_ONE = gql`
  mutation update_consultation_vitals_by_pk(
    $pk_columns: consultation_vitals_pk_columns_input!
    $_set: consultation_vitals_set_input
  ) {
    update_consultation_vitals_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...ConsultationVitalsPart
    }
  }
  ${ConsultationVitalsPart}
`;

const schema = {
  "consultation.vitals.insert.one": VITALS_INSERT_ONE,
  "consultation.vitals.update.one": VITALS_UPDATE_ONE,
};

export default schema;
