import { useMutation } from "services/Client";
import { CONSULTATION_NOTE_GET_MANY } from "services/Client/schemas/queries/consultation_note";
import { useNotification } from "hooks";

export default function useCreateConsultationNote(id, body) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "consultation.note.insert.one",
    variables: {
      object: { consultationId: id, note: { data: { body } } },
    },
    update: (cache, { data: { insert_consultation_note_one } }) => {
      const prevData = cache.readQuery({
        query: CONSULTATION_NOTE_GET_MANY,
        variables: { where: { consultationId: { _eq: id } } },
      });
      const data = {
        consultation_note: [
          insert_consultation_note_one,
          ...prevData.consultation_note,
        ],
      };
      cache.writeQuery({
        query: CONSULTATION_NOTE_GET_MANY,
        data,
        variables: { where: { consultationId: { _eq: id } } },
      });
    },
    onCompleted: (data) => {
      notification.success({
        message: "Remarque Sauvegardée",
      });
    },
  });
  return mutate;
}
