import gql from "graphql-tag";
import { NotePart } from "../fragments/note";
export const NOTE_INSERT_ONE = gql`
  ${NotePart}
  mutation insert_patient_note_one($object: patient_note_insert_input!) {
    insert_patient_note_one(object: $object) {
      id
      note {
        ...NotePart
      }
    }
  }
`;

export const NOTE_UPDATE_ONE = gql`
  ${NotePart}
  mutation update_patient_note_by_pk(
    $pk_columns: patient_note_pk_columns_input!
    $_set: patient_note_set_input
  ) {
    update_patient_note_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
      note {
        ...NotePart
      }
    }
  }
`;

export const NOTE_DELETE = gql`
  ${NotePart}
  mutation delete_patient_note_by_pk($id: uuid!) {
    delete_patient_note_by_pk(id: $id) {
      id
      note {
        ...NotePart
      }
    }
  }
`;

const schema = {
  "patient.note.insert.one": NOTE_INSERT_ONE,
  "patient.note.update.one": NOTE_UPDATE_ONE,
  "patient.note.delete.one": NOTE_DELETE,
};

export default schema;
