import gql from "graphql-tag";

export const TAGS = gql`
  query tag {
    tag {
      id
      label
    }
  }
`;

const schema = {
  "tag.get.many": TAGS,
};
export default schema;
