import { useMutation } from "services/Client";
import gql from "graphql-tag";
import { useNotification } from "hooks";

export default function useCreateNoteTag(id) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "note.tag.insert.one",
    update: (cache, { data: { insert_note_tag_one } }) => {
      const id = insert_note_tag_one.note.id;
      const todo = cache.readFragment({
        id: `note:${id}`, // The value of the to-do item's cache ID
        fragment: gql`
          fragment NotePart on note {
            id
            body
            note_tags {
              tag {
                label
                id
              }
            }
          }
        `,
      });
      const { note_tags, ...rest } = todo;

      cache.writeFragment({
        id: `note:${id}`, // The value of the to-do item's cache ID
        fragment: gql`
          fragment NotePart on note {
            id
            body
            note_tags {
              tag {
                label
                id
              }
            }
          }
        `,
        data: {
          ...rest,
          note_tags: [...note_tags, { tag: insert_note_tag_one.tag }],
        },
      });
    },
    onCompleted: (data) => {
      notification.success({
        message: "Tag Sauvegardé",
      });
    },
  });
  return mutate;
}
