import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'

export default function Paginator({ count, current, handler }) {
  const pages = Array.from({ length: count }, (x, i) => i);
  return (
    <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
      <div className="-mt-px w-0 flex-1 flex">
        {current > 0 && <button onClick={() => handler(current - 1)}
          type="button"
          className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
        >
          <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          Previous
        </button>}
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pages.map(page => (
          page !== current ?
            (<button key={page} onClick={() => handler(page)}
              className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
            >
              {page + 1}
            </button>)
            :
            (<button key={page} onClick={() => handler(page)}
              className="border-blue-500 text-blue-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
              aria-current="page"
            >
              {page + 1}
            </button>)
        ))}
      </div>
      <div className="-mt-px w-0 flex-1 flex justify-end">
        {current + 1 < count && <button onClick={() => handler(current + 1)}
          className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
        >
          Next
          <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>}
      </div>
    </nav>
  )
}
