import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js'
import {Line} from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default function DayChart({data, label}) {
  ChartJS.register(
    CategoryScale,
    Filler,
    LinearScale,
    PointElement,
    LineElement,
    ChartDataLabels,

    Title,
    Tooltip,
    Legend,
  )

  const options = {
    maintainAspectRatio: true,
    tension: 0.4,
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          precision: 0,
        },
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  }
  const dataOfDaysChart = {
    datasets: [
      {
        borderWidth: 1,
        fill: true,
        data,
        label,
        backgroundColor: '#60a5fa',
        borderColor: '#0c4a6e',
      },
    ],
  }
  return <Line data={dataOfDaysChart} options={options} />
}
