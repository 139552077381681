import gql from "graphql-tag";
import { NotePart } from "../fragments/note";

export const CONSULTATION_NOTE_GET_MANY = gql`
  ${NotePart}
  query consultation_note(
    $limit: Int
    $offset: Int
    $order_by: [consultation_note_order_by!]
    $where: consultation_note_bool_exp
  ) {
    consultation_note(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      note {
        ...NotePart
      }
    }
  }
`;

const schema = {
  "consultation.note.get.many": CONSULTATION_NOTE_GET_MANY,
};

export default schema;
