import gql from "graphql-tag";
import { PatientPart } from "../fragments/patient";
export const PATIENT_GET_MANY = gql`
  query patient(
    $limit: Int
    $offset: Int
    $order_by: [patient_order_by!]
    $where: patient_bool_exp
  ) {
    patient(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      ...PatientPart
    }
  }
  ${PatientPart}
`;

export const PATIENT_GET_ONE = gql`
  query patient_by_pk($id: uuid!) {
    patient_by_pk(id: $id) {
      ...PatientPart
    }
  }
  ${PatientPart}
`;

export const PATIENT_COUNT = gql`
  query patient_aggregate {
    patient_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const PATIENT_CREDIT = gql`
  query get_patient_credit($id: uuid!) {
  consultation_aggregate(where: {patientId: {_eq: $id}}) {
    aggregate {
      sum {
        amount_paid
      }
    }
    nodes {
      pricing_plan {
        price
      }
    }
  }
}
`
const schema = {
  "patient.get.many": PATIENT_GET_MANY,
  "patient.get.one": PATIENT_GET_ONE,
  "patient.get.count": PATIENT_COUNT,
  "patient.get.credit": PATIENT_CREDIT
};

export default schema;
