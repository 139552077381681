import gql from "graphql-tag";

export const INSURANCES = gql`
  query insurance {
    insurance {
      id
      name
    }
  }
`;

const schema = {
  "insurance.get.many": INSURANCES,
};
export default schema;
