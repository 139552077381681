import gql from "graphql-tag";
export const PATIENT_FILE_GET_MANY = gql`
  query patient_file(
    $limit: Int
    $offset: Int
    $order_by: [patient_file_order_by!]
    $where: patient_file_bool_exp
  ) {
    patient_file(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      file {
        id
        label
      }
    }
  }
`;

const schema = {
  "patient.file.get.many": PATIENT_FILE_GET_MANY,
};

export default schema;
