import gql from "graphql-tag";
export const NOTE_TAG_INSERT_ONE = gql`
  mutation insert_note_tag_one($object: note_tag_insert_input!) {
    insert_note_tag_one(object: $object) {
      id
      tag {
        id
        label
      }
      note {
        id
        body
      }
    }
  }
`;

export const NOTE_TAG_DELETE = gql`
  mutation delete_note_tag($where: note_tag_bool_exp!) {
    delete_note_tag(where: $where) {
      returning {
        id
        tag {
          id
          label
        }
        note {
          id
          body
        }
      }
    }
  }
`;

const schema = {
  "note.tag.insert.one": NOTE_TAG_INSERT_ONE,
  "note.tag.delete": NOTE_TAG_DELETE,
};

export default schema;
