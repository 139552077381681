import { useMutation } from "services/Client";
import { useNotification } from "hooks";

export default function useUpdateConsultationVitals({ variables, onCompleted }) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "consultation.vitals.update.one",
    variables,
    onCompleted: (data) => {
      notification.success({
        message: "Données Enregistrées",
      });
      onCompleted()
    },
  });
  return mutate;
}
