// import asyncComponent from "shared/helpers/AsyncComponent";
// const Home = asyncComponent(() => import("screens/Main"));
// const Course = asyncComponent(() => import("screens/Courses"));
// const Auth = asyncComponent(() => import("screens/Auth"));
import Home from "screens/home";
import Patients from "screens/patients";
import Patient from "screens/patient";
import Consultation from "screens/consultation";
import Search from "screens/search";
import Settings from "screens/settings";
var navigation = [
  // { path: "/main/:testId/:groupId", key: "Courses", component: Home },
  {
    path: "/home",
    link: "/home",
    key: "Accueil",
    component: Home,
    isPublic: true,
  },
  {
    path: "/patients/*",
    link: "/patients",
    key: "Patients",
    component: Patients,
    isPublic: true,
    isInvisible: true,
  },
  {
    path: "/patient/:id",
    link: "/patient",
    key: "Patient",
    component: Patient,
    isPublic: true,
    isInvisible: true,
  },
  {
    path: "/consultation/:id",
    link: "/consultation",
    key: "Consultation",
    component: Consultation,
    isPublic: true,
    isInvisible: true,
  },
  {
    path: "/search",
    link: "/search",
    key: "Search",
    component: Search,
    isPublic: true,
    isInvisible: true,
  },
  {
    path: "/settings/*",
    link: "/settings",
    key: "Settings",
    component: Settings,
    isPublic: true,
    isInvisible: true,
  },
];

export default navigation;
