import React, { useRef, useState } from "react";
import { useGcal } from "@othpwn/performed-cognito-wrapper";
import { useQuery, useMutation } from "services/Client";
import { useParams, Link } from "react-router-dom";
import get from "lodash/get";
import { CheckIcon, PaperClipIcon, BellIcon } from "@heroicons/react/solid";
import ListItem from "./components/listItem";
import Note from "./components/note";
import {
  useCreateConsultation,
  useCreatePatientFile,
  useUpdatePatient,
} from "./hooks";
import { useUploadFile } from "hooks";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const genders = [
  { id: "male", name: "Homme" },
  { id: "female", name: "Femme" },
  { id: "notsay", name: "Autre" },
];

const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export default function Patient() {
  const { id } = useParams();
  const { groups } = useGcal();
  const [file, setFile] = useState(null);
  const fileInput = useRef(null);
  const { uploadFile, progress: uploadProgress } = useUploadFile();
  const insertPatientFile = useCreatePatientFile(id);

  const updatePatientMutate = useUpdatePatient();

  const { data } = useQuery({
    event: "patient.get.one",
    variables: { id },
    skip: !id,
  });
  const { data: consultationData } = useQuery({
    event: "consultation.get.many",
    variables: {
      where: { patientId: { _eq: id } },
      order_by: { created_at: "desc" },
    },
    skip: !id,
  });

  const { data: patientFileData } = useQuery({
    event: "patient.file.get.many",
    variables: {
      where: { patientId: { _eq: id } },
      order_by: { created_at: "desc" },
    },
    skip: !id || !groups.includes("administrator"),
  });

  const consultations = get(consultationData, "consultation", []);
  const patient_files = get(patientFileData, "patient_file", []);

  const patient = get(data, "patient_by_pk");
  const first_name = get(patient, "first_name", "");
  const last_name = get(patient, "last_name", "");

  const name = first_name + " " + last_name;
  const cin = get(patient, "cin");
  const address = get(patient, "address");
  const gender = get(patient, "gender", "notsay");
  const insuranceId = get(patient, "insurance.id");

  const mutate = useCreateConsultation(id, insuranceId);

  const birth_date = new Date(get(patient, "birth_date")).toLocaleDateString(
    "fr-FR",
    { year: "numeric", month: "long", day: "numeric" }
  );

  const created_at = new Date(get(patient, "created_at")).toLocaleDateString(
    "fr-FR",
    { weekday: "long", year: "numeric", month: "long", day: "numeric" }
  );

  const updatePatient = (column) => (value) => {
    return updatePatientMutate({
      variables: {
        pk_columns: { id },
        _set: { [column]: value },
      },
    });
  };

  const { data: insuranceData } = useQuery({ event: "insurance.get.many" });
  const insurances = get(insuranceData, "insurance", []);

  const { mutate: downloadFile } = useMutation({ event: "file.download" });

  const { data: patientCreditData, loading: patientCreditLoading } = useQuery({
    event: "patient.get.credit",
    variables: { id },
  });
  const consultations_prices =
    patientCreditData?.consultation_aggregate?.nodes || [];
  const patient_credit =
    consultations_prices.length > 0
      ? consultations_prices.reduce(function (acc, obj) {
          return acc + obj?.pricing_plan?.price;
        }, 0) -
        patientCreditData?.consultation_aggregate?.aggregate?.sum?.amount_paid
      : 0;


  return (
    <main className="">
      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="lg:col-span-2 bg-white rounded-lg shadow py-7 sm:rounded-t-lg max-w-3xl  px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5 ">
            <div className="flex-shrink-0">
              <div className="relative">
                {/* <img
                className="h-16 w-16 rounded-full"
                src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                alt=""
              /> */}
                <span
                  className="absolute inset-0 shadow-inner rounded-full"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">{name}</h1>
              <p className="text-sm font-medium text-gray-500">
                Patient depuis{" "}
                <time className="text-gray-900" dateTime="2020-08-25">
                  {created_at}
                </time>
              </p>
            </div>
          </div>
          <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            {/* <button
            type="button"
            className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
          >
            Disqualify
          </button> */}
            <button
              type="button"
              onClick={mutate}
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            >
              Créer Consultation{" "}
            </button>
          </div>
        </div>

        {!patientCreditLoading && (
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">
              Balance Patient
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
              -{patient_credit} MAD
            </dd>
          </div>
        )}
      </div>

      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          {/* Description list*/}
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg p-8">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Informations Patient
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Dossier personnel et médical
                </p>
              </div>
              <div className="mt-5 border-t border-gray-200">
                <dl className="divide-y divide-gray-200">
                  <ListItem
                    value={last_name}
                    submit={updatePatient("last_name")}
                    label="Nom"
                    type="text"
                  />
                  <ListItem
                    value={first_name}
                    submit={updatePatient("first_name")}
                    label="Prénom"
                    type="text"
                  />
                  <ListItem
                    value={gender}
                    submit={updatePatient("gender")}
                    label="Sexe"
                    type="select"
                    selectList={genders}
                  />
                  <ListItem
                    value={insuranceId}
                    submit={updatePatient("insuranceId")}
                    label="Assurance"
                    type="select"
                    selectList={insurances}
                  />
                  <ListItem
                    value={cin}
                    label="CIN"
                    submit={updatePatient("cin")}
                    type="text"
                  />
                  <ListItem
                    value={birth_date}
                    label="Date de naissance"
                    type="date"
                    submit={updatePatient("birth_date")}
                  />
                  <ListItem
                    value={address}
                    label="Adresse"
                    submit={updatePatient("address")}
                    type="text"
                  />

                  {groups.includes("administrator") && (
                    <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Fichiers et documents
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                          {patient_files.map((pf) => (
                            <li
                              key={pf.id}
                              className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                            >
                              <div className="w-0 flex-1 flex items-center">
                                <PaperClipIcon
                                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="ml-2 flex-1 w-0 truncate">
                                  {pf.file.label}
                                </span>
                              </div>
                              <div className="ml-4 flex-shrink-0 flex space-x-4">
                                <button
                                  type="button"
                                  onClick={() => {
                                    downloadFile({
                                      variables: { fileId: pf.file.id },
                                    }).then((res) => {
                                      console.log(res);
                                      openInNewTab(res.data.file_download.url);
                                    });
                                  }}
                                  className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                  Download
                                </button>
                              </div>
                            </li>
                          ))}

                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <span className="ml-2 flex-1 w-0 truncate">
                                {file ? file.name : "Choose file..."}
                              </span>
                            </div>
                            <div className="ml-4 flex-shrink-0 flex space-x-4">
                              <button
                                type="button"
                                onClick={() => fileInput.current.click()}
                                className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                              >
                                Parcourir
                              </button>
                              {file && (
                                <>
                                  <span
                                    className="text-gray-300"
                                    aria-hidden="true"
                                  >
                                    |
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      uploadFile({
                                        file,
                                        fileName: file.name,
                                        fileType: file.type,
                                      }).then((res) =>
                                        insertPatientFile({
                                          variables: {
                                            object: {
                                              file: {
                                                data: {
                                                  path: res,
                                                  label: file.name,
                                                  type: file.type,
                                                },
                                              },
                                              patientId: id,
                                            },
                                          },
                                        }).then(() => setFile(null))
                                      )
                                    }
                                    className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                  >
                                    Envoyer
                                  </button>
                                </>
                              )}
                            </div>
                            <input
                              type="file"
                              accept="audio/mpeg, audio/mp4, image/png, image/jpeg"
                              onChange={(e) => setFile(e.target.files[0])}
                              hidden
                              ref={fileInput}
                            />
                          </li>
                          {!!uploadProgress && (
                            <div className="w-full bg-gray-200 mt-2 rounded-md dark:bg-gray-700">
                              <div
                                className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-md"
                                style={{ width: `${uploadProgress}%` }}
                              >
                                {uploadProgress}%
                              </div>
                            </div>
                          )}
                        </ul>
                      </dd>
                    </div>
                  )}
                </dl>
              </div>
            </div>
          </section>

          {/* Comments*/}
          {groups.includes("administrator") && (
            <section aria-labelledby="notes-title">
              <Note id={id} />
            </section>
          )}
        </div>

        <section
          aria-labelledby="timeline-title"
          className="lg:col-start-3 lg:col-span-1"
        >
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
            <h2
              id="timeline-title"
              className="text-lg font-medium text-gray-900"
            >
              Historique des consultations
            </h2>

            {/* Activity Feed */}
            <div className="mt-6 flow-root">
              <ul className="-mb-8">
                {consultations.map((item, itemIdx) => (
                  <li key={item.id}>
                    <div className="relative pb-8">
                      {itemIdx !== consultations.length - 1 ? (
                        <span
                          className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div>
                          <span
                            className={classNames(
                              item.status === "pending"
                                ? "bg-blue-500"
                                : "bg-green-500",
                              "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                            )}
                          >
                            {item.status === "pending" ? (
                              <BellIcon
                                className="w-5 h-5 text-white"
                                aria-hidden="true"
                              />
                            ) : (
                              <CheckIcon
                                className="w-5 h-5 text-white"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </div>
                        <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                          <div>
                            <p className="text-sm text-gray-500">
                              <Link
                                to={`/consultation/${item.id}`}
                                className="font-medium text-gray-900"
                              >
                                {new Date(item.created_at).toLocaleDateString(
                                  "fr-FR",
                                  {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}
                              </Link>
                            </p>
                          </div>
                          {/* <div className="text-right text-sm whitespace-nowrap text-gray-500">
                            <time dateTime={item.created_at}>
                              {item.created_at}
                            </time>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {/* <div className="mt-6 flex flex-col justify-stretch">
              <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Advance to offer
              </button>
            </div> */}
          </div>
        </section>
      </div>
    </main>
  );
}
