import { useMutation } from "services/Client";
import gql from "graphql-tag";
import { useNotification } from "hooks";

export default function useCreateNoteTag(id) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "note.tag.delete",
    update: (cache, { data: { delete_note_tag } }) => {
      const id = delete_note_tag.returning[0].note.id;
      const tagId = delete_note_tag.returning[0].tag.id
      const todo = cache.readFragment({
        id: `note:${id}`, // The value of the to-do item's cache ID
        fragment: gql`
          fragment NotePart on note {
            id
            body
            note_tags {
              tag {
                label
                id
              }
            }
          }
        `,
      });
      const { note_tags, ...rest } = todo;
      const new_note_tags = note_tags.filter(n=>n.tag.id!==tagId)
      console.log(note_tags)
      cache.writeFragment({
        id: `note:${id}`, // The value of the to-do item's cache ID
        fragment: gql`
          fragment NotePart on note {
            id
            body
            note_tags {
              tag {
                label
                id
              }
            }
          }
        `,
        data: {
          ...rest,
          note_tags: [...new_note_tags],
        },
      });
    },
    onCompleted: (data) => {
      notification.success({
        message: "Tag Supprimé",
      });
    },
  });
  return mutate;
}
