import { useMutation } from "services/Client";
import { useNotification } from "hooks";

export default function useUpdateNote({ onCompleted }) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "note.update.one",
    onCompleted: () => {
      notification.success({
        message: "Remarque Enregistrée",
      });
      onCompleted();
    },
  });
  return mutate;
}
