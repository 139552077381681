import { useMutation } from "services/Client";
import { useNotification } from "hooks";

export default function useUpdatePatient() {
  const notification = useNotification();
  const { mutate: updatePatientMutate } = useMutation({
    event: "patient.update",
    onCompleted: () =>
      notification.success({ message: "Changement Sauvegardé" }),
  });
  return updatePatientMutate;
}
