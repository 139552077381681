import React from "react";
import { RadioGroup } from "@headlessui/react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Pricing({ items, ...rest }) {
  return (
    <RadioGroup {...rest}>
      <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
      <div className="space-y-4">
        {items.map((item) => (
          <RadioGroup.Option
            key={item.id}
            value={item.id}
            className={({ active }) =>
              classNames(
                active ? "ring-1 ring-offset-2 ring-blue-500" : "",
                "relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none"
              )
            }
          >
            {({ checked }) => (
              <>
                <div className="flex items-center">
                  <div className="text-sm">
                    <RadioGroup.Label
                      as="p"
                      className="font-medium text-gray-900"
                    >
                      {item.label}
                    </RadioGroup.Label>
                  </div>
                </div>
                <RadioGroup.Description
                  as="div"
                  className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right"
                >
                  <div className="font-medium text-gray-900">{item.price}</div>
                  <div className="ml-1 text-gray-500 sm:ml-0">MAD</div>
                </RadioGroup.Description>
                <div
                  className={classNames(
                    checked ? "border-blue-500" : "border-transparent",
                    "absolute -inset-px rounded-lg border-2 pointer-events-none"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
