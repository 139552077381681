import gql from "graphql-tag";
import { ConsultationPart } from "../fragments/consultation";

export const CONSULTATION_INSERT_ONE = gql`
  mutation insert_consultation_one($object: consultation_insert_input!) {
    insert_consultation_one(object: $object) {
      ...ConsultationPart
    }
  }
  ${ConsultationPart}
`;

export const CONSULTATION_UPDATE_ONE = gql`
  mutation update_consultation_by_pk(
    $pk_columns: consultation_pk_columns_input!
    $_set: consultation_set_input
  ) {
    update_consultation_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...ConsultationPart
    }
  }
  ${ConsultationPart}
`;



const schema = {
  "consultation.insert.one": CONSULTATION_INSERT_ONE,
  "consultation.update.one": CONSULTATION_UPDATE_ONE,
};

export default schema;
