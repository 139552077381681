import { useState } from "react";
import { useGcal } from "@othpwn/performed-cognito-wrapper";
import { useQuery } from "services/Client";
import moment from "moment";

export default function useUsersPerDay() {
  const { groups } = useGcal();

  const [startdate, setStartdate] = useState(
    moment().subtract(28, "d").format("YYYY-MM-DD")
  );
  const [enddate, setEnddate] = useState(moment().format("YYYY-MM-DD"));
  const [step, setStep] = useState("d");
  const { data } = useQuery({
    event: "patient.get.by.day",
    variables: {
      start: startdate,
      end: enddate,
    },
    skip: !groups.includes("administrator"),
  });
  const { data: patientDataMonth } = useQuery({
    event: "patient.get.by.month",
    variables: {
      start: moment(startdate).set("date", 1).format("YYYY-MM-DD"),
      end: enddate,
    },
    skip: !groups.includes("administrator"),
  });
  const { data: patientDataYear } = useQuery({
    event: "patient.get.by.year",
    variables: {
      start: moment(startdate)
        .set("date", 1)
        .set("month", 0)
        .format("YYYY-MM-DD"),
      end: enddate,
    },
    skip: !groups.includes("administrator"),
  });
  return {
    data:
      step === "d"
        ? data
        : step === "m"
        ? patientDataMonth
        : step === "y"
        ? patientDataYear
        : undefined,
    payload: { startdate, enddate, step },
    handlers: { setStartdate, setEnddate, setStep },
  };
}
