import { gql } from "@apollo/client";

export const NotePart = gql`
  fragment NotePart on note {
    id
    body
    note_tags {
      tag {
        label
        id
      }
    }
  }
`;
