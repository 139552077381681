import gql from "graphql-tag";

export const NOTE_INSERT_ONE = gql`
  mutation insert_consultation_note_one(
    $object: consultation_note_insert_input!
  ) {
    insert_consultation_note_one(object: $object) {
      id
      note {
        id
        body
      }
    }
  }
`;

export const NOTE_UPDATE_ONE = gql`
  mutation update_consultation_note_by_pk(
    $pk_columns: consultation_note_pk_columns_input!
    $_set: consultation_note_set_input
  ) {
    update_consultation_note_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
      note {
        id
        body
      }
    }
  }
`;

export const NOTE_DELETE = gql`
  mutation delete_consultation_note_by_pk($id: uuid!) {
    delete_consultation_note_by_pk(id: $id) {
      id
      note {
        id
        body
      }
    }
  }
`;

const schema = {
  "consultation.note.insert.one": NOTE_INSERT_ONE,
  "consultation.note.update.one": NOTE_UPDATE_ONE,
  "consultation.note.delete.one": NOTE_DELETE,
};

export default schema;
