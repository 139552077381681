import React from 'react'
import DayChart from '../components/DayChart'
import MonthChart from '../components/MonthChart'
import YearChart from '../components/YearChart'

export default function PatientChart({
  data,
  handlers,
  payload,
}) {
  const [dayData, setDayData] = React.useState()
  const [monthData, setMonthData] = React.useState()
  const [yearData, setYearData] = React.useState()

  React.useEffect(() => {
    if (data?.patient_get_count_day) {
      setDayData(
        data?.patient_get_count_day?.map(element => {
          return {
            y: element?.total || 0,
            x: new Intl.DateTimeFormat('fr-FR', {dateStyle: 'medium'}).format(
              new Date(element?.time),
            ),
          }
        }),
      )
    }
    if (data?.patient_get_count_month) {
      setMonthData(
        data?.patient_get_count_month?.map(element => {
          return {
            y: element?.total || 0,
            x: new Intl.DateTimeFormat('fr-FR', {
              month: 'long',
              year: 'numeric',
            }).format(new Date(element?.time)),
          }
        }),
      )
    }
    if (data?.patient_get_count_year) {
      setYearData(
        data?.patient_get_count_year?.map(element => {
          return {
            y: element?.total || 0,
            x: new Intl.DateTimeFormat('fr-FR', {
              year: 'numeric',
            }).format(new Date(element?.time)),
          }
        }),
      )
    }
  }, [data])
  return (
    <div className="p-4 bg-white shadow-md rounded-lg">
      <div className="flex justify-between items-center mb-5 flex-col sm:flex-row">
        <span className="text-lg font-bold">Patients</span>
        <div className="flex sm:space-x-2 space-x-0 sm:flex-row flex-col sm:space-y-0 space-y-2 w-full sm:w-auto">
          <div className="flex flex-col">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Intervalle
            </label>
            <select
              id="step"
              name="step"
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              defaultValue={payload.step}
              onChange={e => handlers.setStep(e.target.value)}
            >
              <option value="d">Jour</option>
              <option value="m">Mois</option>
              <option value="y">Année</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Date début
            </label>
            <input
              required
              type={payload.step !== 'd' ? 'month' : 'date'}
              value={
                payload.step !== 'd'
                  ? `${new Intl.DateTimeFormat('fr-FR', {
                      year: 'numeric',
                    }).format(
                      new Date(payload.startdate),
                    )}-${new Intl.DateTimeFormat('fr-FR', {
                      month: '2-digit',
                    }).format(new Date(payload.startdate))}`
                  : `${new Date(
                      payload.startdate,
                    ).getFullYear()}-${new Intl.DateTimeFormat('fr-FR', {
                      month: '2-digit',
                    }).format(
                      new Date(payload.startdate),
                    )}-${new Intl.DateTimeFormat('fr-FR', {
                      day: '2-digit',
                    }).format(new Date(payload.startdate))}`
              }
              onChange={e => {
                handlers.setStartdate(new Date(e.target.value).toDateString())
              }}
              className="rounded-md text-sm text-gray-600 border-gray-400"
            />
          </div>
          <div className="flex flex-col">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Date fin
            </label>{' '}
            <input
              required
              type={payload.step !== 'd' ? 'month' : 'date'}
              value={
                payload.step !== 'd'
                  ? `${new Date(
                      payload.enddate,
                    ).getFullYear()}-${new Intl.DateTimeFormat('fr-FR', {
                      month: '2-digit',
                    }).format(new Date(payload.enddate))}`
                  : `${new Date(
                      payload.enddate,
                    ).getFullYear()}-${new Intl.DateTimeFormat('fr-FR', {
                      month: '2-digit',
                    }).format(
                      new Date(payload.enddate),
                    )}-${new Intl.DateTimeFormat('fr-FR', {
                      day: '2-digit',
                    }).format(new Date(payload.enddate))}`
              }
              onChange={e =>
                handlers.setEnddate(new Date(e.target.value).toDateString())
              }
              className="rounded-md text-sm text-gray-600 border-gray-400"
            />
          </div>
        </div>
      </div>
      {payload.step === 'd' && <DayChart data={dayData ?? []} label={"Patients"} />}
      {payload.step === 'm' && <MonthChart data={monthData ?? []} label={"Patients"}/>}
      {payload.step === 'y' && <YearChart data={yearData ?? []} label={"Patients"}/>}
    </div>
  )
}
