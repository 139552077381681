import React, { useState, useEffect } from "react";
import get from "lodash/get"
export default function ListItem({ label, value, type, selectList, submit }) {
  const [edit, setEdit] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 flex text-sm items-center text-gray-900 sm:mt-0 sm:col-span-2">
        {!edit ? (
          type === "select" ? (
            <span className="flex-grow">{get(selectList.filter((item) => item.id === value), "[0].name")}</span>
          ) : (
            <span className="flex-grow">{value}</span>
          )
        ) : type !== "select" ? (
          <input
            name="patient"
            id="patient"
            type={type}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          />
        ) : (
          <select
            id="gender"
            name="gender"
            onChange={(e) => setInputValue(e.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            defaultValue={value}
          >
            {selectList.map((c) => (
              <option value={c.id}>{c.name}</option>
            ))}
          </select>
        )}
        <span className="ml-4 flex-shrink-0">
          {edit ? (
            <button
              type="button"
              onClick={() => submit(inputValue).then(() => setEdit(false))}
              className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Enregistrer
            </button>
          ) : (
            <button
              type="button"
              onClick={() => setEdit(true)}
              className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Modifier
            </button>
          )}
        </span>
      </dd>
    </div>
  );
}
