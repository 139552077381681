import useTerminateConsultation from "./useTerminateConsultation";
import useCreateConsultationNote from "./useCreateConsultationNote";
import useUpdateConsultationPlan from "./useUpdateConsultationPlan";
import useCreateConsultationVitals from "./useCreateConsultationVitals";
import useUpdateConsultationVitals from "./useUpdateConsultationVitals";
import useDeleteConsultationNote from "./useDeleteConsultationNote";
import useUpdateConsultationNote from "./useUpdateConsultationNote";
export { useTerminateConsultation };
export { useCreateConsultationNote };
export { useUpdateConsultationPlan };
export { useCreateConsultationVitals };
export { useUpdateConsultationVitals };
export { useDeleteConsultationNote };
export { useUpdateConsultationNote };

const hooks = {
  useTerminateConsultation,
  useCreateConsultationNote,
  useUpdateConsultationPlan,
  useCreateConsultationVitals,
  useUpdateConsultationVitals,
  useDeleteConsultationNote,
  useUpdateConsultationNote
};

export default hooks;
