import React from "react";
import get from "lodash/get";
import { useGcal } from "@othpwn/performed-cognito-wrapper";
import { Link } from "react-router-dom";
import { useQuery } from "services/Client";
import CreatePatient from "shared/CreatePatient";
import ListPatient from "./components/ListPatient";
import { useUsersPerDay, useConsultationChart, usePaymentChart } from "hooks";
import PatientChart from "shared/containers/PatientChart";
import ConsultationChart from "shared/containers/ConsultationChart";
import PaymentChart from "shared/containers/PaymentChart";
import SplashScreen from "shared/pages/SplashScreen";

export default function Home() {
  const { groups } = useGcal();

  const { data: patientCount, loading: loading1 } = useQuery({
    event: "patient.get.count",
  });
  const count = get(patientCount, "patient_aggregate.aggregate.count", 0);
  const { data: consultationCount, loading: loading2 } = useQuery({
    event: "consultation.get.count",
  });
  const ctCount = get(
    consultationCount,
    "consultation_aggregate.aggregate.count",
    0
  );
  const { data, loading: loading3 } = useQuery({ event: "get.user.profile" });

  const user = get(data, "get_user_profile", {
    first_name: "",
    last_name: "",
    email: "",
    picture: null,
    role: "",
    title: "",
    organization: { name: "" },
  });
  const { data: usersDayData, payload, handlers } = useUsersPerDay();
  const {
    data: consultationChartData,
    payload: consultationPayload,
    handlers: consultationHandlers,
  } = useConsultationChart();
  const {
    data: paymentChartData,
    payload: paymentPayload,
    handlers: paymentHandlers,
  } = usePaymentChart();

  if (loading1 || loading2 || loading3) return <SplashScreen />;
  return (
    <>
      <h1 className="sr-only">Profile</h1>
      {/* Main 3 column grid */}
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
        {/* Left column */}
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          {/* Welcome panel */}
          <section aria-labelledby="profile-overview-title">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              <h2 className="sr-only" id="profile-overview-title">
                Profile Overview
              </h2>
              <div className="bg-white p-6">
                <div className="sm:flex sm:items-center sm:justify-between">
                  <div className="sm:flex sm:space-x-5">
                    <div className="flex-shrink-0">
                      {user.picture ? (
                        <img
                          className="mx-auto h-20 w-20 rounded-full object-cover"
                          src={user.picture}
                          alt=""
                        />
                      ) : (
                        <span className="flex mx-auto h-20 w-20 rounded-full overflow-hidden bg-gray-100">
                          <svg
                            className="h-full w-full text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        </span>
                      )}
                    </div>
                    <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                      <p className="text-sm font-medium text-gray-600">
                        Bonjour,
                      </p>
                      <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                        {user.first_name} {user.last_name}
                        <span className="ml-2 inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
                          {user.role}
                        </span>
                      </p>
                      <p className="text-sm font-medium text-gray-600">
                        {user.title} @{user.organization.name}
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 flex justify-center sm:mt-0">
                    <Link
                      to={"/settings"}
                      className="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Paramètres
                    </Link>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
                <div className="px-6 py-5 text-sm font-medium text-center">
                  <span className="text-gray-900">{count}</span>{" "}
                  <span className="text-gray-600">patients</span>
                </div>
                <div className="px-6 py-5 text-sm font-medium text-center">
                  <span className="text-gray-900">{ctCount}</span>{" "}
                  <span className="text-gray-600">consultations</span>
                </div>
              </div>
            </div>
          </section>

          {/* Actions panel */}
          <CreatePatient className="col-span-2 md:col-span-1" />
          {groups.includes("administrator") && (
            <PatientChart
              data={usersDayData}
              handlers={handlers}
              payload={payload}
            />
          )}
          {groups.includes("administrator") && (
            <ConsultationChart
              data={consultationChartData}
              handlers={consultationHandlers}
              payload={consultationPayload}
            />
          )}
          {groups.includes("administrator") && (
            <PaymentChart
              data={paymentChartData}
              handlers={paymentHandlers}
              payload={paymentPayload}
            />
          )}
        </div>

        {/* Right column */}
        <div className="grid grid-cols-1 gap-4">
          {/* Recent Patients */}
          <section aria-labelledby="recent-patients-title">
            <ListPatient />
          </section>
        </div>
      </div>
    </>
  );
}
