import React from "react";
import DayChart from "../components/DayChart";
import MonthChart from "../components/MonthChart";
import YearChart from "../components/YearChart";

export default function PatientChart({ data, handlers, payload }) {
  const [dayData, setDayData] = React.useState();
  const [monthData, setMonthData] = React.useState();
  const [yearData, setYearData] = React.useState();

  React.useEffect(() => {
    if (data?.consultation_get_count_day) {
      setDayData(
        data?.consultation_get_count_day?.map((element) => {
          return {
            y: element?.total || 0,
            x: new Intl.DateTimeFormat("fr-FR", { dateStyle: "medium" }).format(
              new Date(element?.time)
            ),
          };
        })
      );
    }
    if (data?.consultation_get_count_month) {
      setMonthData(
        data?.consultation_get_count_month?.map((element) => {
          return {
            y: element?.total || 0,
            x: new Intl.DateTimeFormat("fr-FR", {
              month: "long",
              year: "numeric",
            }).format(new Date(element?.time)),
          };
        })
      );
    }
    if (data?.consultation_get_count_year) {
      setYearData(
        data?.consultation_get_count_year?.map((element) => {
          return {
            y: element?.total || 0,
            x: new Intl.DateTimeFormat("fr-FR", {
              year: "numeric",
            }).format(new Date(element?.time)),
          };
        })
      );
    }
  }, [data]);
  return (
    <div className="p-4 bg-white shadow-md rounded-lg">
      <div className="flex justify-between items-center mb-5 flex-col sm:flex-row">
        <span className="text-lg font-bold">Consultations</span>
        <div className="flex sm:space-x-2 space-x-0 sm:flex-row flex-col sm:space-y-0 space-y-2 w-full sm:w-auto">
          <div className="flex flex-col items-center">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Assurance
            </label>
            <input
              id="insurance"
              aria-describedby="insurance-toggle"
              name="insurance"
              type="checkbox"
              checked={payload.hasInsurance}
              onChange={(e) => handlers.setHasInsurance(e.target.checked)}
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          </div>
          <div className="flex flex-col">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Statut
            </label>
            <select
              id="step"
              name="step"
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              defaultValue={payload.status}
              onChange={(e) => handlers.setStatus(e.target.value)}
            >
              <option value="finished">Terminée</option>
              <option value="pending">En Cours</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Intervalle
            </label>
            <select
              id="step"
              name="step"
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              defaultValue={payload.step}
              onChange={(e) => handlers.setStep(e.target.value)}
            >
              <option value="d">Jour</option>
              <option value="m">Mois</option>
              <option value="y">Année</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Date début
            </label>
            <input
              required
              type={payload.step !== "d" ? "month" : "date"}
              value={
                payload.step !== "d"
                  ? `${new Intl.DateTimeFormat("fr-FR", {
                      year: "numeric",
                    }).format(
                      new Date(payload.startdate)
                    )}-${new Intl.DateTimeFormat("fr-FR", {
                      month: "2-digit",
                    }).format(new Date(payload.startdate))}`
                  : `${new Date(
                      payload.startdate
                    ).getFullYear()}-${new Intl.DateTimeFormat("fr-FR", {
                      month: "2-digit",
                    }).format(
                      new Date(payload.startdate)
                    )}-${new Intl.DateTimeFormat("fr-FR", {
                      day: "2-digit",
                    }).format(new Date(payload.startdate))}`
              }
              onChange={(e) => {
                handlers.setStartdate(new Date(e.target.value).toDateString());
              }}
              className="rounded-md text-sm text-gray-600 border-gray-400"
            />
          </div>
          <div className="flex flex-col">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Date fin
            </label>{" "}
            <input
              required
              type={payload.step !== "d" ? "month" : "date"}
              value={
                payload.step !== "d"
                  ? `${new Date(
                      payload.enddate
                    ).getFullYear()}-${new Intl.DateTimeFormat("fr-FR", {
                      month: "2-digit",
                    }).format(new Date(payload.enddate))}`
                  : `${new Date(
                      payload.enddate
                    ).getFullYear()}-${new Intl.DateTimeFormat("fr-FR", {
                      month: "2-digit",
                    }).format(
                      new Date(payload.enddate)
                    )}-${new Intl.DateTimeFormat("fr-FR", {
                      day: "2-digit",
                    }).format(new Date(payload.enddate))}`
              }
              onChange={(e) =>
                handlers.setEnddate(new Date(e.target.value).toDateString())
              }
              className="rounded-md text-sm text-gray-600 border-gray-400"
            />
          </div>
        </div>
      </div>
      {payload.step === "d" && (
        <DayChart data={dayData ?? []} label={"Consultations"} />
      )}
      {payload.step === "m" && (
        <MonthChart data={monthData ?? []} label={"Consultations"} />
      )}
      {payload.step === "y" && (
        <YearChart data={yearData ?? []} label={"Consultations"} />
      )}
    </div>
  );
}
