import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import mutations from "./schemas/mutations";
import { useNotification } from "hooks";

function useMutationWrapper({ event, ...rest }) {
  const notification = useNotification();

  const [mutate, { loading }] = useMutation(mutations[event], {
    onError({ graphQLErrors }) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case "data-exception":
            notification.error({ message: "Données Incomplète" });
            break;
          case "validation-failed":
            notification.error({ message: "Données Invalides" });
            return;
          case "constraint-violation":
            notification.error({ message: err.extensions.message });
            return;
          default:
            return;
        }
      }
    },
    ...rest,
  });
  return { mutate, loading };
}

useMutationWrapper.propTypes = {
  event: PropTypes.string.isRequired,
  update: PropTypes.func,
};

export default useMutationWrapper;
