import gql from "graphql-tag";
import { NotePart } from "../fragments/note";

export const PATIENT_NOTE_GET_MANY = gql`
  ${NotePart}
  query patient_note(
    $limit: Int
    $offset: Int
    $order_by: [patient_note_order_by!]
    $where: patient_note_bool_exp
  ) {
    patient_note(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      id
      note {
        ...NotePart
      }
    }
  }
`;

const schema = {
  "patient.note.get.many": PATIENT_NOTE_GET_MANY,
};

export default schema;
