import { useMutation } from "services/Client";
import { useNavigate } from "react-router-dom";
import get from "lodash/get";
import { CONSULTATION_GET_MANY } from "services/Client/schemas/queries/consultation";
import { useNotification } from "hooks";

export default function useCreateConsultation(id, insuranceId) {
  const notification = useNotification();
  let navigate = useNavigate();
  const { mutate } = useMutation({
    event: "consultation.insert.one",
    variables: { object: { patientId: id, insuranceId } },
    update: (cache, { data: { insert_consultation_one } }) => {
      const prevConsultations = cache.readQuery({
        query: CONSULTATION_GET_MANY,
        variables: {
          where: { patientId: { _eq: id } },
          order_by: { created_at: "desc" },
        },
      });
      const data = {
        consultation: [
          insert_consultation_one,
          ...prevConsultations.consultation,
        ],
      };
      cache.writeQuery({
        query: CONSULTATION_GET_MANY,
        data,
        variables: {
          where: { patientId: { _eq: id } },
          order_by: { created_at: "desc" },
        },
      });
    },
    onCompleted: (data) => {
      const id = get(data, "insert_consultation_one.id");
      notification.success({
        message: "Consultation Créée",
        buttonLabel: "Visiter Consultation",
        buttonAction: () => navigate(`/consultation/${id}`),
      });
    },
  });
  return mutate;
}
